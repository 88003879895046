.fmc-snackbar-wrapper {
    display: flex;
    justify-content: center;

    &.fmc-snackbar-wrapper--fixed {
        align-items: end;
        position: fixed;
        width: 100%;
        bottom: fds-rem(30px);
        left: 0;
        @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
            bottom: fds-rem(60px);
        }
    }
}

.fmc-snackbar {
    --background-color: var(--fds-color--primary);
    --text-color: var(--fds-color--white);
    --font-size: #{fds-rem(11px)};
    --font-size-cta: var(--font-size);
    --line-height: #{fds-rem(16px)};
    --padding: #{fds-rem(0 16px)};
    --border-color: var(--fds-color--white);
    --border-thickness: #{fds-rem(1px)};
    --border: var(--border-thickness) solid var(--border-color);
    --margin-left--action: #{fds-rem(16px)};
    --shadow: var(--fmc-elevation__box-shadow--layer3);
    --outline-offset: #{fds-rem(6px)};
    --dismiss-icon-size: #{fds-rem(24px)};

    box-shadow: var(--shadow);
    padding: var(--padding);
    border: var(--border);
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: var(--line-height);
    font-size: var(--font-size);
    min-height: fds-rem(48px);
    max-height: fds-rem(240px);
    transition: var(--fmc-transition-duration) var(--fmc-transition-timing);
    overflow: hidden;

    width: 100%;
    @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
        width: auto;
    }
    min-width: fds-rem(320px);

    display: flex;
    align-items: center;

    &[hidden] {
        opacity: 0;
        min-height: 0;
        max-height: 0;
        border-top-width: 0;
        border-bottom-width: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .fmc-snackbar__content {
        padding: fds-rem(15px 0); // 15px to adjust for 1px border
        margin-right: auto;
    }

    .fmc-snackbar__dismiss,
    .fmc-snackbar__cta {
        color: var(--text-color);

        &:focus-visible {
            outline: var(--fds-outline-dark);
            outline-offset: var(--outline-offset);
        }
    }

    .fmc-snackbar__dismiss {
        margin-left: var(--margin-left--action);

        --icon-size: var(--dismiss-icon-size);
    }

    .fmc-snackbar__cta {
        margin-left: var(--margin-left--action);
        font-size: var(--font-size-cta);
        text-transform: uppercase;
    }
}
