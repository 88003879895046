.fmc-cards {
  &__card {
    &--dark {
      background-color: $fds-color--primary;
      color: $fds-color--white;
      .fmc-cards__title, 
      .fmc-cards__subtitle, 
      .fmc-cards__body,
      .fmc-cards__footer {
        color: $fds-color--white;
      }
      
      [class*="fmc-type--"] {
        color: $fds-color--white;
      }
      
      [class*="fmc-divider--"] {
        border-color: $fds-color--white;
      }
    }
  }
}