$nav-box-shadow: inset 0 0 0 transparent, 0 0.2rem 0.2rem 0 rgb(0 0 0 / 50%);

.om-fmc__navbar {
    &__utilitynav {
        background-color: $fds-color--fourth;
    }
    &--mobile {
        position: sticky;
        background-color: $fds-color--primary;
        color: $fds-color--white;
        -webkit-position: sticky;
        box-shadow: $nav-box-shadow;
        top: 0;
        z-index: 100;

        .om-fmc__navbar--mobile-grid {
            @include media('<=fds-md') {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            @include media('<=fds-sm') {
                padding-left: 0rem;
                padding-right: 0rem;
            }
            .om-fmc__navbar--mobile-container {
                @include media('<=fds-sm') {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        .om-fmc__navbar--mobile__nav-wrapper {
            background-color: $fds-color--white;
            position: absolute;
            width: 100vw;
            left: 0;
            top: 10rem;
            height: calc(100vh - 10rem);
            overflow-y: scroll;
            z-index: 100;
            transform: scaleY(0);
            transition: transform 0.25s ease-in-out;
            transform-origin: top;
            touch-action: none;
            pointer-events: none;
        }

        .onemag-fds__navbar--mobile__nav-button {
            &.onemag-fds__navbar--mobile-nav-button--closed {
                display: initial;
            }
            &.onemag-fds__navbar--mobile-nav-button--opened {
                display: none;
            }
            .fds-icon {
                color: $fds-color--white;
            }
        }
        .onemag-fds__navbar--mobile-logo--opened {
            display: none;
        }
        .onemag-fds__navbar--mobile-logo--closed {
            display: initial;
        }

        &.opened {
            background-color: $fds-color--white;
            color: $fds-color--primary;
            &:not(.om-fmc__navbar--mobile-transparent)::after {
                content: '';
                height: 2px;
                position: absolute;
                top: 10rem;
                left: 0;
                width: 100%;
                z-index: 100;
                box-shadow: $nav-box-shadow;
                background-color: $fds-color--white;
            }

            .onemag-fds__navbar--mobile-logo--opened {
                display: initial;
            }
            .onemag-fds__navbar--mobile-logo--closed {
                display: none;
            }
            .onemag-fds__navbar--mobile__nav-button {
                &.onemag-fds__navbar--mobile-nav-button--closed {
                    display: none;
                }
                &.onemag-fds__navbar--mobile-nav-button--opened {
                    display: initial;
                }
                .fds-icon {
                    color: $fds-color--primary;
                }
            }
            .om-fmc__navbar--mobile__nav-wrapper {
                touch-action: initial;
                pointer-events: initial;
                transform: scaleY(1);
            }

            .om-fmc__navbar--mobile-grid.fds-layout-grid {
                height: 120px !important;
            }

            @media only screen and (max-width: 600px) {
                .om-fmc__navbar--mobile-grid.fds-layout-grid {
                    height: 110px !important;
                }
                .fds-img {
                    height: 90px;
                }
            }
        }

        .om-fmc__navbar--mobile__subnav-menu {
            overflow: hidden;
            touch-action: none;
            pointer-events: none;
            transform: scaleY(0);
            height: 0;
            transition: transform 0.25s ease-in-out;
            transform-origin: top;
            &.opened {
                height: initial;
                touch-action: initial;
                pointer-events: initial;
                transform: scaleY(1);
            }
        }
        .om-fmc__navbar--secondnav {
            background-color: $fds-color--fourth;
        }
    }
    &--mobile .om-fmc__navbar__utilitynav--mobile {
        background-color: $fds-color--primary;
        color: $fds-color--white;
        .fmc-text-button span {
            color: $fds-color--white;
        }
        .om-fmc-spacer::after {
            background-color: $fds-color--white;
        }
    }
    &--mobile.opened .om-fmc__navbar__utilitynav--mobile,
    &--mobile.opened .om-fmc__navbar--secondnav {
        display: none;
    }

    &.om-fmc__navbar--transparent {
        background: transparent;
        z-index: 1000;
        top: 0;
        left: 0;
        position: absolute;
        &.opened {
            background-color: $fds-color--white;
            border-bottom: 1px solid --fds-color--black-40;
        }
    }

    &.om-fmc__navbar--mobile-transparent {
        background: transparent;
        box-shadow: none;
        .onemag-fds__navbar--mobile__nav-button .fds-icon {
            color: $fds-color--primary;
        }
    }
}

.om-fmc__subnavbar--sticky {
    position: sticky;
    z-index: 99;
    top: 0;
    @include media('<fds-lg') {
        top: fds-rem(235px);
    }
}

.nav-background-color {
    background-color: #ffffff !important;
    box-shadow: unset;
}

.om-fmc__navbar--mobile.nav-background-color .onemag-fds__navbar--mobile__nav-button .fds-icon {
    color: var(--fds-color--primary) !important;
}
