.fds-segmented-control {
    display: grid;
    grid-template-columns:
        [full-start] minmax(0, 1fr)
        [main-start] minmax(0, 60em)
        [main-end] minmax(0, 1fr);
    overflow: hidden;
    margin: auto calc(var(--fds-layout-grid-margin-xs) * -1);
    padding-top: fds-rem(10px);

    @media (min-width: map-get($fds-breakpoints, 'fds-sm')) {
        margin: auto;
    }
}

// Active
.fds-segmented-control__button--active {
    & .fds-segmented-control__button::before {
        transform: scale(1);
    }
}

// Focus
button.fds-segmented-control__button--focused {
    outline-width: fds-rem(1px);
    outline-style: solid;
    outline-offset: fds-rem(20px);
}

.fds-segmented-control__button-list-flex-container {
    display: flex;
    justify-content: center;
    padding: fds-rem(5px 0 40px);
    grid-column: full;
}

.fds-segmented-control__button-list-wrapper {
    position: relative;
}

.fds-segmented-control__button-list {
    position: relative;
    display: inline-flex;
    border-radius: fds-rem(
        999px
    ); // for pill shape regardless of dimensions: https://www.w3.org/TR/css-backgrounds-3/#corner-overlap
    border: 1px solid transparent;
    padding: fds-rem(8px);
}

.fds-segmented-control__button {
    display: inline-flex;
    padding: fds-rem(0 24px);
    text-decoration: none;
    background: none;
    border: none;
    border-right: 1px solid #d1d1d1;

    // remove flash on iOS mobile as accessibility indicators are customized in this CSS
    -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

    // prevent button elements from shifting in IE11
    top: 0;
    left: 0;

    &:last-child {
        border-right: none;
    }
}

.fds-segmented-control__button-text {
    @extend %fmc-type--body1;
    display: block;
    white-space: nowrap;
    color: var(--fds-color--primary);
}

.fds-segmented-control__button-text,
.fds-segmented-control__slider-text {
    &::after {
        font-family: 'FordIcons' !important;
        line-height: fds-rem(16px);
        font-size: fds-rem(16px);
        padding-left: fds-rem(16px);
    }
}

.fds-segmented-control__slider-text::after {
    color: var(--fds-color--white);
}

.fds-segmented-control__slider {
    @extend %fmc-type--body1;
    display: block;
    margin: 0 auto;
    padding: fds-rem(12px 40px);
    border: 1px solid transparent;
    border-radius: fds-rem(
        999px
    ); // for pill shape regardless of dimensions: https://www.w3.org/TR/css-backgrounds-3/#corner-overlap
    position: absolute;
    top: fds-rem(-2px);
    left: 0;
    white-space: nowrap;
    box-shadow: $fds-elevation__box-shadow--layer2;
    transition: 0.3s ease-in-out;
}

.fds-segmented-control__panels {
    grid-column: full;
}

.fds-segmented-control__panel {
    display: none;

    &.fds-segmented-control__panel--show {
        display: flex;
    }
}

.fds-color__bg--light {
    & .fds-segmented-control__button--focused {
        outline-color: var(--fds-color--primary);
    }

    & .fds-segmented-control__button-list {
        border-color: var(--fds-color--gray2);
    }

    & .fds-segmented-control__slider {
        border-color: var(--fds-color--primary);
        background-color: var(--fds-color--primary);
        color: var(--fds-color--white);

        & .fds-segmented-control__button-text::after {
            color: var(--fds-color--white);
        }
    }
}

.fds-segmented-control__mobile {
    & .fds-segmented-control__button-list-flex-container {
        display: block;
    }

    & .fds-segmented-control__button-list {
        transition: left 0.3s ease-in-out;
    }
}
