.fds-tracker__scroll-container {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.fds-tracker__scroll-container::-webkit-scrollbar {
    /* WebKit */
    display: none;
}

.fds-tracker__button-list {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    justify-items: center;
    margin-top: fds-rem(21px); // need spacing at top for focus outline
    position: relative;
    width: max-content;
}

.fds-tracker__button {
    background-color: var(--fds-color--white);
    border-radius: 100%;
    box-sizing: content-box;
    grid-row: 1;
    padding: fds-rem(5px);
    position: relative;
    text-align: center;
    transition: all 0.3s linear;
    z-index: 1;
}

.fds-tracker__button .fds-tracker__position-label::after {
    font-family: 'FordIcons';
    font-size: fds-rem(16px);
}

.fds-tracker__position-label {
    @include fds-get-component-typeset('tracker');

    border: fds-rem(1px) solid var(--fds-color--gray2);
    border-radius: 100%;
    display: block;
    height: fds-rem(45px);
    position: relative;
    transition: all 0.3s linear;
    width: fds-rem(45px);
}

.fds-tracker__button--filled:not(.fds-tracker__button--active):not(.fds-tracker__button--disabled) {
    cursor: default;

    & .fds-tracker__position-label {
        background-color: var(--fds-color--success1);
        border-color: var(--fds-color--success1);
        color: var(--fds-color--white);
    }

    & .fds-tracker__label-text {
        opacity: 0;
        transition: all 0.3s linear;
    }

    & .fds-tracker__position-label::after {
        bottom: 0;
        content: $fds-font--ford-icons__success;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.fds-tracker__button--editable:not(.fds-tracker__button--active):not(.fds-tracker__button--disabled) {
    cursor: pointer;

    & .fds-tracker__position-label::after {
        content: $fds-font--ford-icons__edit;
    }

    &:hover {
        .fds-tracker__position-label {
            background-color: var(--fds-color--secondary);
            box-shadow: $fds-elevation__box-shadow--layer3;
        }
    }
}

.fds-tracker__button--disabled {
    cursor: default;
}

.fds-tracker__button--active {
    cursor: default;

    & .fds-tracker__position-label {
        background-color: var(--fds-color--primary);
        border-color: var(--fds-color--primary);
        box-shadow: $fds-elevation__box-shadow--layer2;
        color: var(--fds-color--white);
    }
}

.fds-tracker__button--focused::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: fds-rem(1px) solid var(--fds-color--primary);
    outline-offset: fds-rem(10px);
}

.fds-tracker__label-text {
    display: block;
}

.fds-tracker__title {
    align-self: flex-start;
    grid-row: 2;
    margin-top: fds-rem(10px);
    max-width: 30vw;
    padding: 0 fds-rem(8px);
    text-align: center;
}

.fds-tracker__progress-line-wrapper {
    grid-row: 1;
    min-width: fds-rem(50px);
    width: 100%;

    @include media('>=fds-sm') {
        min-width: fds-rem(100px);
    }
}

.fds-tracker__progress-line-wrapper--filled .fds-tracker__progress-line--filled {
    width: 100%;
}

.fds-tracker__progress-line {
    background-color: var(--fds-color--gray2);
    display: block;
    height: fds-rem(1px);
    width: 100%;
}

.fds-tracker__progress-line--filled {
    background-color: var(--fds-color--primary);
    display: block;
    height: fds-rem(3px);
    position: relative;
    transition: width 0.3s ease-in-out;
    width: 0;
}

// simple trackers
.fds-tracker--simple {
    & .fds-tracker__scroll-container {
        margin-bottom: fds-rem(-65px);
        margin-top: fds-rem(-65px);
    }

    & .fds-tracker__button-list {
        margin-bottom: fds-rem(65px);
        margin-top: fds-rem(65px);
    }

    &
        .fds-tracker__button--filled:not(.fds-tracker__button--active):not(.fds-tracker__button--disabled),
    &
        .fds-tracker__button--editable:not(.fds-tracker__button--active):not(.fds-tracker__button--disabled) {
        & .fds-tracker__position-label::after {
            content: none;
        }
    }

    & .fds-tracker__button {
        padding: fds-rem(3px);
    }

    & .fds-tracker__position-label {
        height: fds-rem(20px);
        width: fds-rem(20px);
    }

    & .fds-tooltip {
        grid-row: 1;
    }

    & .fds-tooltip .fds-tooltip__button {
        cursor: default;
        height: fds-rem(28px);
        width: fds-rem(28px);
    }
}
