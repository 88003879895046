.fmc-overflow-menu {
    --border-diff: 16px;

    list-style: none;
    display: flex;
    flex-direction: column;
    min-width: 148px;
}

.fmc-overflow-menu__item {
    font-size: fds-rem(16px);
    display: flex;
    align-items: center;
    height: fds-rem(48px);
    position: relative;

    @include fds-set-hover {
        cursor: pointer;
        background: var(--fds-color--gray2);

        .fds-icon,
        .fmc-overflow-menu__label {
            color: var(--fds-color--white);
        }
    }
}

.fmc-overflow-menu__item--border::before {
    content: '';
    height: 1px;
    width: calc(100% - var(--border-diff));
    position: absolute;
    top: 0;
    left: calc(var(--border-diff) / 2);
    background: var(--fds-color--black);
}

.fmc-overflow-menu__label {
    color: var(--fds-color--primary);
    margin: 0 fds-rem(16px);
    border: 0;
    background: none;
}
