.fds-text-field {
    & .fds-text-field__label-wrapper {
        display: flex;
        align-items: flex-end;
    }

    & .fds-text-field__label-icon {
        display: none;
    }
}

.fds-text-field__input--disabled {
    & .fds-text-field__input-wrapper {
        background-color: var(--fds-color--disabled3);
    }

    & .fds-text-field__input::placeholder {
        color: var(--fds-color--white) !important;
    }
}

.fds-text-field--dirty {
    &.fds-text-field__input-wrapper {
        background-color: var(--fds-color--white);
    }
}

.fds-text-field__input--focused {
    outline: 1px solid var(--fds-color--primary);
    outline-offset: fds-rem(10px);
}

.fds-text-field__input--active {
    & .fds-text-field__input-wrapper {
        box-shadow: 0 0 0 transparent inset, $fds-elevation__box-shadow--layer2;
        background-color: var(--fds-color--white);
    }

    & .fds-text-field__input {
        color: var(--fds-color--primary);
    }
}

.fds-text-field--dirty,
.fds-text-field__input--active,
.fds-text-field--valid,
.fds-text-field__input--valid {
    & .fds-text-field__label {
        position: relative;
        opacity: 1;
        margin-right: fds-rem(5px);
    }
}

.fds-text-field--valid {
    &.fds-text-field--dirty {
        & .fds-text-field__status {
            display: flex;
            padding: 0;
            height: 0;
        }
    }
}

.fds-text-field__eyebrow {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fds-rem(16px);
    margin-bottom: fds-rem(13px);
}

.fds-text-field__label {
    position: absolute;
    white-space: nowrap;
    opacity: 0;
    font-size: fds-rem(12px);
    line-height: 1;
    letter-spacing: fds-rem(1px);
    color: var(--fds-color--gray3);
    transition: opacity 0.3s ease-in-out;
}

.fds-text-field__input-wrapper {
    position: relative;
    width: 100%;
    height: fds-rem(50px);
    border: solid fds-rem(1px) var(--fds-color--gray2);
    background-color: transparent;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    resize: none;
    border-radius: fds-rem(3px);
}

.fds-text-field__input {
    @extend %fmc-type--body1;
    padding: 0 fds-rem(15px);
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    -webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */

    &::placeholder {
        opacity: 1;
    }

    // remove red box-shadow (border) automatically applied by Firefox
    &:invalid {
        box-shadow: none;
    }
}

.fds-text-field__input,
.fds-text-field__input::placeholder {
    color: var(--fds-color--gray3);
}

input:-webkit-autofill {
    -webkit-text-fill-color: var(--fds-color--gray3);
}

.fds-text-field__message {
    opacity: 0;
    width: 100%;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
}

.fds-text-field__status {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: fds-rem(7px);
}

.fds-text-field__input--invalid {
    & .fds-text-field__input-wrapper {
        border-color: var(--fds-color--error1);
    }

    & .fds-text-field__label {
        transition-duration: 0s;
    }

    & .fds-text-field__label-icon {
        display: block;
    }
}

.fds-text-field__message--valid {
    display: block;
    opacity: 0.01;
    height: 0;
}

.fds-text-field--invalid,
.fds-text-field__input--invalid {
    & .fds-text-field__status {
        display: flex;
    }

    & .fds-text-field__message {
        opacity: 1;
        color: var(--fds-color--error1);
    }
}

.fds-text-field--valid {
    /* stylelint-disable no-duplicate-selectors */
    & .fds-text-field__message {
        color: var(--fds-color--gray3);
    }
}

.fds-text-field__input--valid {
    & .fds-text-field__input-wrapper {
        border-color: var(--fds-color--success1);
    }

    & .fds-text-field__label-icon {
        display: block;
    }
}
