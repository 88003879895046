// ================================
// Flex Layout
// ================================

// Amount of gutter spacing to be added on each side of a column
$fds-flex__gutter: 10px !default;

// Number of columns to be generated for flexbox columns
$fds-flex__columns: 12 !default;

// Define max-widths used to generate container classes
$fds-flex__containers: (
    xs: fds-rem(519px, $fds-base-multiplier-rem),
    sm: fds-rem(879px, $fds-base-multiplier-rem),
    md: fds-rem(1127px, $fds-base-multiplier-rem),
    lg: fds-rem(1607px, $fds-base-multiplier-rem),
) !default;

// Gutter spacing to be added on each side of container
$fds-flex__container-gutter--small: 16px !default;
$fds-flex__container-gutter--large: 24px !default;
