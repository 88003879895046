.om-fmc-table--wrapper {
  @include media('<fds-md') {
    overflow: scroll;
  }
}

.om-fmc-table--fluid {
  overflow-y: scroll;
  .om-fmc-table--fluid__sticky-header {
    position: sticky;
    -webkit-position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($fds-color--white, 1);
  }
}

.om-side-tabs__tabpanel .om-fmc-table--fluid .om-fmc-table--fluid__sticky-header {
  background-color: rgba($fds-color--gray1, 1);
}

.om-fmc-table--fluid::-webkit-scrollbar {
  display: none;
}

table.om-fmc-table {
  min-width: fds-em(1024px);
  border-color: rgba($fds-color--white, 0.2);

  &:not(.fmc-table--no-borders) th:first-child {
    border-left-color: rgba($fds-color--white, 0);
  }
  &:not(.fmc-table--no-borders) th:last-child {
    border-right-color: rgba($fds-color--white, 0);
  } 
  &:not(.fmc-table--no-borders) th {
    border-top-color: rgba($fds-color--white, 0);
  }

  thead {
    tr th {
      background-color: rgba($fds-color--white, 0);
      border-top-color: rgba($fds-color--white, 0);
      border-right-color: rgba($fds-color--white, 0);
      border-left-color: rgba($fds-color--white, 0);
      border-bottom-color: rgba($fds-color--black, 0.2);
      color: $fds-color--primary;
      vertical-align: middle;
      text-align: center;
    }
  }
  tbody {
    tr td {
      vertical-align: middle;
      text-align: center;
      padding: 2rem 1.6rem;
      background-color: rgba($fds-color--white, 0);
      border-color: rgba($fds-color--black, 0.2);
      &:first-child {
        border-left-color: rgba($fds-color--white, 0);
      }
      &:last-child {
        border-right-color: rgba($fds-color--white, 0);
      }
    }

    tr td.om-fmc-table__cell--primary {
      background-color: $fds-color--primary;
      color: $fds-color--white;
      border-bottom-color: rgba($fds-color--white, 0.4);
    }
    
    tr td.om-fmc-table__cell--tertiary {
      background-color: rgba($fds-color--tertiary, 0.7);
      color: $fds-color--white;
      border-bottom-color: rgba($fds-color--white, 0.4);
    }

    tr td.om-fmc-table__cell--gray1 {
      background-color: $fds-color--gray1;
      color: $fds-color--primary;
    }

    tr td.om-fmc-table__cell--gray2 {
      background-color: $fds-color--gray2;
      color: $fds-color--white;
    }
    
    tr td.om-fmc-table__cell--gray3 {
      background-color: $fds-color--gray3;
      color: $fds-color--white;
    }
    
    tr td.om-fmc-table__cell--gray4 {
      background-color: $fds-color--gray4;
      color: $fds-color--white;
    }

    tr td.om-fmc-table__cell--gray5 {
      background-color: $fds-color--gray5;
      color: $fds-color--white;
    }
    
    tr::last-child  td{
      border-bottom-color: rgba($fds-color--white, 0);
    }

    tr.om-fmc-table__row--no-bottom-border td {
      border-bottom-color: rgba($fds-color--white, 0);
    }

    tr td.om-fmc-table__col--no-bottom-border {
      border-bottom-color: rgba($fds-color--white, 0);
    }
  }
}