%fmc-input {
    --shadow: none;
    --shadow-hover: #{$fds-elevation__box-shadow--layer3};
    --background-color: transparent;
    --background-color-focus: var(--fds-color--white);
    --border-color: var(--fds-color--gray2);
    --text-color: var(--fds-color--primary);
    --text-color-disabled: var(--fds-color--white);
    --placeholder-text-color: var(--fds-color--gray3);
    --placeholder-letter-spacing: #{fds-rem(1px)};
    --border-weight: 0.1rem;
    --border-bottom-weight: 0.1rem;
    --border-bottom-weight-focus: 0.2rem;
    --border-all: 0.1rem solid var(--border-color);
    --border-bottom: var(--border-all);
    --border-bottom-focus: var(--border-all);
    --border-radius: 0.3rem;
    --height: #{fds-rem(48px)};
    --padding-left: #{fds-rem(16px)};
    --padding-top: 0;
    --padding-right: var(--padding-left);
    --padding-bottom: 0;

    @include fds-set-brand(lincoln) {
        --border-color: var(--fds-color--primary);
        --border-color-focus: var(--fds-color--secondary);
        --border-radius: 0;
        --border-all: none;
        --border-bottom: var(--border-bottom-weight) solid var(--border-color);
        --background-color: var(--fds-color--gray2);
        --background-color-focus: var(--fds-color--gray2);
        --border-bottom-focus: var(--border-bottom-weight) solid var(--border-color-focus);
        --shadow-hover: none;
        --height: 6rem;
        --padding-left: #{fds-rem(14px)};
        --padding-top: #{fds-rem(1px)}; // prevents shifting text on lincoln focus
        --placeholder-text-color: var(--fds-color--primary);
        --placeholder-letter-spacing: 0;
    }

    display: block;
    width: 100%;
    height: var(--height);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    font-size: 1.6rem;
    color: var(--text-color);
    border: var(--border-all);
    border-bottom: var(--border-bottom);
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    background-repeat: no-repeat;
    background-position: right 1.5rem center;
    background-size: 16px;
    line-height: 2rem;
    appearance: none;
    outline-offset: var(--fds-outline-offset);
    box-shadow: var(--shadow);
    will-change: box-shadow;
    transition: box-shadow var(--fmc-transition-duration) var(--fmc-transition-timing),
        background-color var(--fmc-transition-duration) var(--fmc-transition-timing);

    // set focus (instead of focus-visible) to align browser behavior
    // and enhance accessibility by always showing focus on elements
    // that receive keyboard input
    &:focus {
        --shadow: var(--shadow-hover);
        --background-color: var(--background-color-focus);
        --border-bottom-weight: var(--border-bottom-weight-focus);
        --border-bottom: var(--border-bottom-focus);
        outline: var(--fds-outline); // necessary specificity to override global :focus
    }

    &::placeholder {
        color: var(--placeholder-text-color);
        letter-spacing: var(--placeholder-letter-spacing);
    }

    @include fds-set-disabled {
        --background-color: var(--fds-color--disabled3);
        --text-color: var(--text-color-disabled);
        --placeholder-text-color: var(--text-color-disabled);
    }
}

%fmc-focus-wrapper:focus-within {
    outline: var(--fds-outline);
    outline-offset: var(--fds-outline-offset);

    * {
        outline: none;
    }
}

.fmc-input {
    @extend %fmc-input;
}

.fmc-input--success {
    --border-color: var(--fds-color--success1) !important;
    --border-bottom-weight: 0.2rem;
}

.fmc-input--error {
    --border-color: var(--fds-color--error1) !important;
    --border-bottom-weight: 0.2rem;
}

.fmc-select {
    @extend %fmc-input;

    @supports not (appearance: none) {
        /* stylelint-disable property-no-vendor-prefix */
        -moz-appearance: none;
        -webkit-appearance: none;
        /* stylelint-enable property-no-vendor-prefix */
    }

    --padding-right: #{fds-rem(40px)};

    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNiA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+SWNvbnMgLyBDaGV2cm9uIC8gRG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29ucy0vLUNoZXZyb24tLy1Eb3duIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNNC4xMzU4NTE0OSwxMS4xNTczNzQgQzMuOTQ2NjIzNSwxMS4zNTg0ODkzIDMuOTU2MjYwMSwxMS42NzQ5MjUxIDQuMTU3Mzc1NDQsMTEuODY0MTUzMSBDNC4zNTg0OTA3OCwxMi4wNTMzODExIDQuNjc0OTI2NTcsMTIuMDQzNzQ0NSA0Ljg2NDE1NDU2LDExLjg0MjYyOTIgTDExLjg2NDE1NDYsNC40MDI4ODc1MyBDMTIuMDQ0MjI3Myw0LjIxMTUwMjUzIDEyLjA0NTQyMjEsMy45MTMzODQzNyAxMS44NjY4ODkxLDMuNzIwNTYyMTggTDQuODY2ODg5MTUsLTMuODM5Njk2MTcgQzQuNjc5Mjc5MjUsLTQuMDQyMzIxNzggNC4zNjI5MzA4NywtNC4wNTQ0OTQ0MiA0LjE2MDMwNTI2LC0zLjg2Njg4NDUzIEMzLjk1NzY3OTY1LC0zLjY3OTI3NDYzIDMuOTQ1NTA3MDEsLTMuMzYyOTI2MjUgNC4xMzMxMTY5LC0zLjE2MDMwMDY0IEwxMC44MTYwNTQ3LDQuMDU3NTE4ODQgTDQuMTM1ODUxNDksMTEuMTU3Mzc0IFoiIGZpbGw9IiMxMDJCNEUiIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC4wMDAwMDIsIDQuMDAwMDAxKSBzY2FsZSgxLCAtMSkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtOC4wMDAwMDIsIC00LjAwMDAwMSkgIj48L3BhdGg+CiAgICA8L2c+Cjwvc3ZnPg==');

    @include fds-set-brand(lincoln) {
        background-position: top 2.5rem right 1.5rem; // prevent chevron for shifting on hover
    }

    @include fds-set-disabled {
        background-image: none;
    }
}

.fmc-label {
    display: inline-block;
    margin-bottom: 1rem;
}
