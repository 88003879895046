// ================================
// Tools: Set hover state
// ================================

// Sets a hover and focus-visible state

@mixin fds-set-hover() {
    &:hover {
        @content;
    }

    &:focus-visible {
        @content;
    }
}
