.fds-cards {
    position: relative;
    height: 100%;
}

.fds-cards__card {
    display: flex;
    flex-direction: column;
    border-radius: fds-rem(3px);
    overflow: hidden;
    background-color: var(--fds-color--white);
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        outline: var(--fds-color--primary) solid fds-rem(1px);
        outline-offset: fds-rem(10px);
    }
}

.fds-cards__card-carousel {
    height: 100%;
}

.fds-cards__card--horizontal {
    & .fds-cards__media-area {
        order: -1;
    }

    @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
        flex-direction: row;

        & .fds-cards__footer {
            align-items: unset;
        }

        & .fds-cards__media-area {
            order: unset;
            flex: 1;
            display: flex;

            & img {
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.fds-cards__card--selected {
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1,
        0 0 0 fds-rem(1px) var(--fds-color--primary);
}

@media (min-width: map-get($fds-breakpoints, 'fds-md')) {
    .fds-cards__card--horizontal-25 {
        & .fds-cards__media-area {
            flex: 1;
        }

        & .fds-cards__content-wrapper {
            flex: 3;
        }
    }

    .fds-cards__card--horizontal-75 {
        & .fds-cards__media-area {
            flex: 3;
        }

        & .fds-cards__content-wrapper {
            flex: 1;
        }
    }

    .fds-cards__card--horizontal-reverse {
        & .fds-cards__content-wrapper {
            order: -1;
        }
    }
}

.fds-cards__card--quiet {
    background-color: unset;
    box-shadow: unset;
    border-radius: unset;
    overflow: visible;

    .fds-cards__media-area {
        border-radius: fds-rem(3px);
        overflow: hidden;
        box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1;
    }
}

.fds-cards__card:not(.fds-cards__card--quiet) {
    // using the same selector multiple times increases specificity and avoids the need for !important
    // it's a bit of a hack, but safer than !important
    .fds-primary-button__button.fds-primary-button__button.fds-primary-button__button::before {
        box-shadow: none;
    }

    .fds-primary-button.fds-primary-button--hover {
        .fds-primary-button__button.fds-primary-button__button.fds-primary-button__button::before {
            box-shadow: none;
        }
    }
}

.fds-cards__media-area {
    position: relative;

    > img {
        display: block;
        width: 100%;
    }
}

.fds-cards__alert {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: fds-rem(10px 20px);
    @extend %fmc-type--content2;
    color: var(--fds-color--white);
    background-color: var(--fds-color--primary);
}

.fds-cards__alert--success {
    background-color: var(--fds-color--success1);
}

.fds-cards__alert--error {
    background-color: var(--fds-color--error1);
}

.fds-cards__alert--caution {
    background-color: var(--fds-color--caution2);
}

.fds-cards__content {
    padding: fds-rem(30px) fds-rem(20px);
    display: flex;
    flex-direction: column;
    flex: 1;

    > *:not(:last-child) {
        margin-bottom: fds-rem(20px);
    }
}

.fds-cards__content-wrapper {
    display: flex;
    flex: 1;
}

.fds-cards__title {
    @extend %fmc-type--heading6;
}

.fds-cards__subtitle {
    @extend %fmc-type--subtitle2;
}

.fds-cards__body {
    @extend %fmc-type--body1;
}

.fds-cards__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: fds-rem(30px) 0 0 0;
    margin: auto 0 0;
    border-top: 1px solid var(--fds-color--black-40);

    > *:not(:last-child) {
        margin-bottom: fds-rem(20px);
    }
}

.fds-cards__quiet-card {
    background: none;
    box-shadow: none;

    & .fds-cards__image-wrapper {
        box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer1;
    }
}
