.fmc-accordion__panel .fmc-accordion__button.fmc-accordion__button--row {
  display: flex;
  grid: unset;
  flex-wrap: wrap;
  justify-items: flex-start;
  align-items: unset;

  @media (min-width: map-get($fds-breakpoints, 'fds-md')) {
      grid: unset;
  }
}

.fmc-accordion__panel {
  --expand-icon-transform--expanded: rotate(-180deg);
  border-bottom: 1px solid var(--fds-color--gray2) !important;

  &.fmc-accordion--expanded {
      --expand-icon-transform: var(--expand-icon-transform--expanded);
      .fmc-accordion__button.fmc-accordion__button--row {
          .fmc-accordion__button-expand {
              transform: var(--expand-icon-transform);
          }
      }
  }
}

.label-primary-color span {
  color: var(--fds-color--primary) !important;
}

@media only screen and (max-width: 1024px) {
  .larger-font {
      font-size: 1.6rem !important;
  }

  .show-content {
      height: fit-content !important;
  }

  .fds-app
      .fmc-accordion__button
      .fmc-accordion__button-title-wrapper
      .fmc-accordion__button-description-text {
      font-size: 1.4rem !important;
      font-weight: 300;
  }
  .fmc-accordion__panel.cvc-accordion-panel.accordion-panel-color-fill
      > h3
      > .fmc-accordion__button {
      margin: 0 !important;
      padding-bottom: 4rem !important;
      padding-top: 4rem !important;
  }
}
