.fds-pagination {
    display: flex;
    list-style: none;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.fds-pagination__item {
    display: flex;
    margin: 0 fds-rem(8px);
}

.fds-pagination__link {
    padding: 0.8rem 1.4rem;
    min-width: fds-rem(40px);
    text-align: center;
    align-self: center;
    color: var(--fds-color--gray3);

    &:focus-visible {
        outline: var(--fds-color--primary) solid fds-rem(1px);
        color: var(--fds-color--secondary);
    }
}

.fds-pagination__active {
    border-bottom: 2px solid var(--fds-color--primary);
    color: var(--fds-color--primary);
}

.fds-pagination__prev,
.fds-pagination__next {
    color: var(--fds-color--primary);
    font-size: fds-rem(18px);
    padding: 1.4rem;
    white-space: nowrap;

    &:focus-visible {
        outline: var(--fds-color--primary) solid fds-rem(1px);
        color: var(--fds-color--secondary);
    }

    &::before,
    &::after {
        font-family: 'FordIcons' !important;
    }
}

.fds-pagination__prev::before {
    padding-right: fds-rem(16px);
    content: $fds-font--ford-icons__chevron-left;
}

.fds-pagination__next::after {
    padding-left: fds-rem(16px);
    content: $fds-font--ford-icons__chevron-right;
}

.fds-pagination__disabled {
    color: var(--fds-color--gray2);
    pointer-events: none;
}
