// ================================
// CSS Root Variables
// ================================

:root {
    // get-rem function base multiplier
    --fds-base-multiplier-rem: #{$fds-base-multiplier-rem};

    // Layout Grid:
    @each $size in map-keys($fds-layout-grid-columns) {
        --fds-layout-grid-padding-#{$size}: #{map-get($fds-layout-grid-padding, $size)};
        --fds-layout-grid-margin-#{$size}: #{map-get($fds-layout-grid-default-margin, $size)};
        --fds-layout-grid-gutter-#{$size}: #{map-get($fds-layout-grid-default-gutter, $size)};
        --fds-layout-grid-column-width-#{$size}: #{map-get($fds-layout-grid-column-width, $size)};
    }

    // Color
    // -----

    // principal colors
    --fds-color--primary: #{$fds-color--primary}; // ford blue
    --fds-color--secondary: #{$fds-color--secondary}; // grabber
    --fds-color--tertiary: #{$fds-color--tertiary}; // twilight
    --fds-color--fourth: #{$fds-color--fourth}; // surface
    --fds-color--white: #{$fds-color--white};
    --fds-color--black: #{$fds-color--black};
    --fds-color--gray1: #{$fds-color--gray1}; // light
    --fds-color--gray2: #{$fds-color--gray2}; // medium
    --fds-color--gray3: #{$fds-color--gray3}; // dark
    --fds-color--disabled1: #{$fds-color--disabled1}; // on graphical
    --fds-color--disabled2: #{$fds-color--disabled2}; // on dark
    --fds-color--disabled3: #{$fds-color--disabled3}; // on light
    --fds-color--caution1: #{$fds-color--caution1}; // on graphical
    --fds-color--caution2: #{$fds-color--caution2}; // on light
    --fds-color--error1: #{$fds-color--error1}; // on light
    --fds-color--error2: #{$fds-color--error2}; // on dark
    --fds-color--success1: #{$fds-color--success1}; // on light
    --fds-color--success2: #{$fds-color--success2}; // on dark

    // transparent colors
    --fds-color--primary-5: #{$fds-color--primary-5};
    --fds-color--primary-50: #{$fds-color--primary-50};
    --fds-color--primary-90: #{$fds-color--primary-90};
    --fds-color--tertiary-0: #{$fds-color--tertiary-0};
    --fds-color--tertiary-40: #{$fds-color--tertiary-40};
    --fds-color--tertiary-70: #{$fds-color--tertiary-70};
    --fds-color--tertiary-100: #{$fds-color--tertiary-100};
    --fds-color--black-20: #{$fds-color--black-20};
    --fds-color--black-40: #{$fds-color--black-40};
    --fds-color--black-70: #{$fds-color--black-70};
    --fds-color--white-0: #{$fds-color--white-0};
    --fds-color--white-10: #{$fds-color--white-10};
    --fds-color--white-50: #{$fds-color--white-50};
    --fds-color--white-80: #{$fds-color--white-80};

    // Fonts
    // -----

    --fmc-font--system: -apple-system, blinkmacsystemfont, 'Segoe UI', helvetica, arial, sans-serif;
    --fmc-font--mono: 'Lucida Sans Typewriter', 'Lucida Console', monaco, 'Bitstream Vera Sans Mono',
        monospace;
    --fmc-font--antenna: 'FordAntenna', Arial, Helvetica, sans-serif;
    --fmc-font--miller: 'MillerBWGL', 'Times New Roman', Times, serif;
    --fmc-font--proxima-nova: 'ProximaNova', Arial, Helvetica, sans-serif;

    // Icons
    // -----

    --fmc-font--ford-icons: 'FordIcons';
    --fds-font--ford-icons__play-arrow: '\e900';
    --fds-font--ford-icons__unmask: '\e90a';
    --fds-font--ford-icons__mask: '\e908';
    --fds-font--ford-icons__success: '\e903';
    --fds-font--ford-icons__error: '\e90b';
    --fds-font--ford-icons__clear: '\e909';
    --fds-font--ford-icons__tick: '\e91d';
    --fds-font--ford-icons__chevron-left: '\e90c';
    --fds-font--ford-icons__chevron-right: '\e90d';
    --fds-font--ford-icons__chevron-up: '\e907';
    --fds-font--ford-icons__chevron-down: '\e906';
    --fds-font--ford-icons__chevron-filled-right: '\e91a';
    --fds-font--ford-icons__chevron-filled-left: '\e902';
    --fds-font--ford-icons__edit: '\e90e';
    --fds-font--ford-icons__info-outlined: '\e905';
    --fds-font--ford-icons__info-filled: '\e901';
    --fds-font--ford-icons__warning-filled: '\e990';
    --fds-font--ford-icons__check-filled: '\e90f';
    --fds-font--ford-icons__dark-car-rear: '\e910';
    --fds-font--ford-icons__dark-car-side: '\e911';
    --fds-font--ford-icons__dark-seat: '\e912';
    --fds-font--ford-icons__search: '\e913';
    --fds-font--ford-icons__chat: '\e914';
    --fds-font--ford-icons__ellipsis: '\e915';
    --fds-font--ford-icons__minus: '\e916';
    --fds-font--ford-icons__plus: '\e917';
    --fds-font--ford-icons__long-arrow-back: '\e918';
    --fds-font--ford-icons__long-arrow-forward: '\e919';

    // Type
    // ----

    // Root
    --fmc-type__base-font: var(--fmc-font--antenna);

    // Type Letter Spacing
    --fmc-type__leading-default: 0;
    --fmc-type__leading-narrow: #{fds-rem(1px)};
    --fmc-type__leading-wide: #{fds-rem(2px)};

    // Type Weight
    --fmc-type__weight-light: 300;
    --fmc-type__weight-normal: 400;
    --fmc-type__weight-medium: 500;
    --fmc-type__weight-bold: 700;

    // Elevation
    // ---------

    --fmc-elevation__box-shadow--layer1: #{$fds-elevation__box-shadow--layer1};
    --fmc-elevation__box-shadow--layer2: #{$fds-elevation__box-shadow--layer2};
    --fmc-elevation__box-shadow--layer3: #{$fds-elevation__box-shadow--layer3};

    // Accessibility
    // -------------

    --fds-outline: 1px solid var(--fds-color--primary);
    --fds-outline-dark: 1px solid var(--fds-color--white);
    --fds-outline-offset: #{fds-rem(8px)};

    // Spacing
    // -------

    @each $index, $size in $fmc-spacing-sizes {
        $pixels: $size * 1px;

        --fmc-spacing--#{$index}: #{fds-rem($pixels)};
    }

    // Motion
    // ------

    --fmc-transition-duration: #{$fmc-transition-duration};
    --fmc-transition-timing: #{$fmc-transition-timing};
}

html {
    box-sizing: border-box;
    font-size: $fds-base-font-size-percentage; // Base font size
}

*[data-brand='lincoln'] {
    // Colors
    --fds-color--primary: #324047; // graphite
    --fds-color--secondary: #f26147; // coral
    --fds-color--tertiary: #ecedee; // graphite light
    --fds-color--black: #22292b;
    --fds-color--gray1: #f6f7f7;
    --fds-color--gray2: #ecedee;
    --fds-color--gray3: #233338; // graphite dark
    --fds-color--gray4: #919191; // components
    --fds-color--disabled1: #717171; // graphical
    --fds-color--disabled2: #e0e0e0; // on dark
    --fds-color--disabled3: #54565b; // on light
    --fds-color--caution1: #fcded8; // attention
    --fds-color--error1: #b00020; // on light
    --fds-color--error2: #ff8787; // on dark
    --fds-color--success1: #097a3a; // on light
    --fds-color--success2: #54d089; // on dark

    // Type
    // ----

    // Root
    --fmc-type__base-font: var(--fmc-font--proxima-nova);

    // Accessibility
    // -------------
    --fds-outline-offset: 0.3rem;
}
