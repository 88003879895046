.message-center {
  --icon-gap: #{fds-rem(0.8rem)};
  --dot-size: #{fds-rem(1.2rem)};
	
	.message .category__dot {
		display: inline-block;
		width: var(--dot-size);
		height: var(--dot-size);
		margin-right: var(--icon-gap);
		border-radius: 50%;
		&.category__dot--notice {
			background-color: $fds-color--secondary;
		}
		&.category__dot--materials {
			background-color: rgb(182, 32, 224);
		}
		&.category__dot--notification {
			background-color: rgb(113, 103, 40);
		}
		&.category__dot--report {
			background-color: rgb(98, 54, 255);
		}
		&.category__dot--announcement {
			background-color: $fds-color--primary;
		}
		&.category__dot--training {
			background-color: rgb(18, 125, 130);
		}
		&.category__dot--guide {
			background-color: rgb(224, 11, 121);
		}
		&.category__dot--smart-marketing {
			background-color: rgb(22, 116, 147);
		}
	}

}

