@import '../fds-system/src/scss/fds';
@import './components/navigation.scss';
@import './components/layout.scss';
@import './components/cards.scss';
@import './components/tabs.scss';
@import './components/badge.scss';
@import './components/table.scss';
@import './components/typography.scss';
@import '../fds-system/src/scss/layout/margin';
@import '../fds-system/src/scss/layout/padding';
@import './components/accordion.scss';
@import './components/icon.scss';
@import './components/message-center.scss';
@import './components/async-select.scss';

:root {
  --fds-layout-grid-padding-lg: 60px;
  --fds-layout-grid-margin-lg: 12px;
  --fds-layout-grid-gutter-lg: 12px;
  --fds-layout-grid-padding-md: 30px;
  --fds-layout-grid-margin-md: 0px;
  --fds-layout-grid-gutter-md: 0px;
}

body {
	font-size: 16px;
}

main {
  .fds-type--body1 a,
  .fds-type--body2 a {
    color: var(--fds-color--secondary) !important;
    text-decoration: underline;
    &:hover {
      color: var(--fds-color--primary) !important;
    }
  }
}

.fds-weight--light {
  font-weight: 300 !important;
}

.fds-weight--normal {
  font-weight: 400 !important;
}

.fds-weight--medium {
  font-weight: 500 !important;
}

.fds-weight--bold {
  font-weight: 700 !important;
}

.fmc-dialog {
  z-index: 1000;
}

.fmc-tooltip.fmc-tooltip__nav .fmc-tooltip__content {
  max-width: 45rem;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: 'auto';
}

.w-100 {
  width: 100%;
}

.fds-flex__flex-auto {
  flex: 1 1 auto;
}

ul li.fmc-cards__image,
ul li.fds-img {
  list-style: none;
}

.fds-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.fds-color--primary {
  color: $fds-color--primary !important;
}

.fds-color--secondary {
  color: $fds-color--secondary !important;
}

.fds-color--white {
  color: $fds-color--white !important;
}
.fds-color--black {
  color: $fds-color--black !important;
}
.fds-color--gray1 {
  color: $fds-color--gray1 !important;
}
.fds-color--gray2 {
  color: $fds-color--gray2 !important;
}
.fds-color--gray3 {
  color: $fds-color--gray3 !important;
}
.fds-color--gray4 {
  color: $fds-color--gray4 !important;
}
.fds-color--gray5 {
  color: $fds-color--gray5 !important;
}
.fds-color--caution1 {
  color: $fds-color--caution1 !important;
}
.fds-color--error1 {
  color: $fds-color--error1 !important;
}
.fds-color--error2 {
  color: $fds-color--error2 !important;
}

.fds-color--success1 {
  color: $fds-color--success1 !important;
}

.fds-color--success2 {
  color: $fds-color--success2 !important;
}

.om-fmc-important {
  &.fds-color--white {
    color: $fds-color--white !important;
  }
  &.fds-color--black {
    color: $fds-color--black !important;
  }
  &.fds-color--gray1 {
    color: $fds-color--gray1 !important;
  }
  &.fds-color--gray2 {
    color: $fds-color--gray2 !important;
  }
  &.fds-color--gray3 {
    color: $fds-color--gray3 !important;
  }
  &.fds-color--gray4 {
    color: $fds-color--gray4 !important;
  }
  &.fds-color--gray5 {
    color: $fds-color--gray5 !important;
  }
  &.fds-color--caution1 {
    color: $fds-color--caution1 !important;
  }
  &.fds-hide {
    display: none !important;
  }
}

.om-align--end-v {
  align-self: end;
}

.om-benefit-card__card-list {
  overflow: hidden scroll;
  height: 35vw;
  max-height: 140px;
  @include media('<=fds-lg') {
    max-height: 120px;
  }
  @include media('<=fds-md') {
    max-height: 400px;
  }
}

.om-list-style--none {
  list-style: none;
}

.om-fmc-divider--none {
  border: none;
}

.om-fmc-divider--primary {
  border-color: $fds-color--primary;
}

.fmc-button {
  white-space: nowrap;
}

.fmc-overflow-menu__item {
  height: unset;
  min-height: 4.8rem;
}

.fmc-input--wrapper * {
  color: $fds-color--primary;
}

.om-apexcharts {
  .apexcharts-legend {
    @extend .fds-flex__row;
    margin: 0 auto;
    max-width: fds-rem(520px);
    .apexcharts-legend-series {
      @extend .fds-flex__col-6;
    }
  }
  .om-apexcharts-annotation {
    tspan:nth-child(2) {
      @extend .fds-weight--bold;
    }
  }
} 

.fmc-side-tabs__tab.fds-stretch--normal {
  @extend .fds-stretch--normal;
}

.fds-flex--column {
  display: flex;
  flex-flow: column;
}

.fmc-check:not(.om-fmc-radio-check) {
  max-width: 2rem;
}

.fmc-button--active {
	--text-decoration: underline;
}


.fmc-tooltip .fmc-tooltip__wrapper {
  z-index: 102;
}

.fds-app .fds-checkbox .fmc-type--checkbox-label {
  font-size: 1.6rem !important;
  line-height: 1.6rem !important;
}

.fds-app .fds-icon--16 {
  font-size: 1.6rem !important;
}

.fds-app .fds-checkbox .fds-checkbox__input,
.fds-app .fds-checkbox .fds-checkbox__check,
.fds-app .fds-checkbox .fds-checkbox__box {
  width: 1.6rem !important;
  height: 1.6rem !important;
}

.fds-app .fmc-accordion__button {
  margin-top: 1.6rem !important;
  margin-bottom: 1.6rem !important;
  .fmc-accordion__button-text{
    font-size: fds-rem(20px) !important;
  }
}

.fds-list-square {
  display: list-item;
  list-style-type: square;
}

.cvc-crumb {
  cursor: pointer;
}

.cvc-crumb.cvc-crumb-active {
  cursor: auto;
}

.cvc-word-wrap-break {
  overflow-wrap: break-word;
}

.cvc-accordion-panel .fmc-accordion__body {
  max-height: 45vh;
  overflow-y: scroll !important;
}

.cvc-accordion-sub-title {
  font-size: 1.8rem;
  padding: 2rem 1rem;
  padding-left: 1.8rem;
  background: #eee;
  border-bottom: 1px solid #eee;
  margin-bottom: 2rem !important;
  margin-left: -2rem !important;
  margin-top: -2rem !important;
  margin-right: -2rem !important;
}

.cvc-cursor--pointer {
  cursor: pointer;
}

.fmc-text-button.optanon-toggle-display {
  color: #fff !important;
}

.cvc-filter-accordion-panel {
  .fmc-type--checkbox-label {
    color: #00095b !important;
  }
}

.fds-dealer-card {
  cursor: pointer
}

.fds-carousel-indicator__pancake-pagination-wrapper {
  display: flex;
  padding: 0 1rem;
  align-items: center;
}

.fds-carousel-indicator-line {
  padding: 0 0.625rem;
}

.fds-carousel-indicator-line:after {
  content: " ";
  width: 2rem;
  height: 1px;
  position: relative;
  display: block;
  background-color: var(--fds-color--disabled3);
  border-radius: 2rem;
}

.fds-carousel-indicator-line.fds-carousel-indicator-line__active:after {
  height: 3px;
  background-color: var(--fds-color--primary);
}

#form-dealer-search .fmc-input--wrapper label {
  padding-bottom: 0.25rem;
}