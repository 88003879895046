// ================================
// Font Faces
// ================================

// Type
// weights per https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
// Antenna
@include font-face(FordAntenna, #{$fds-ford-font-directory}/ antenna-light, 300); // light
@include font-face(FordAntenna, #{$fds-ford-font-directory}/ antenna-regular, 400); // regular
@include font-face(FordAntenna, #{$fds-ford-font-directory}/ antenna-medium, 500); // medium
@include font-face(FordAntenna, #{$fds-ford-font-directory}/ antenna-bold, 700); // bold

// Antenna Italic
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-light-italic,
    300,
    italic
); // light
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-regular-italic,
    400,
    italic
); // regular
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-medium-italic,
    500,
    italic
); // medium
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-bold-italic,
    700,
    italic
); // bold

// Antenna Condensed
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-cond-light,
    300,
    normal,
    condensed
); // light
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-cond-regular,
    400,
    normal,
    condensed
); // regular
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-cond-medium,
    500,
    normal,
    condensed
); // medium
@include font-face(
    FordAntenna,
    #{$fds-ford-font-directory}/ antenna-cond-bold,
    700,
    normal,
    condensed
); // bold

// Icons
@include font-face(FordIcons, #{$fds-ford-icon-directory}/ ford-icons-v2);

// Lincoln Miller
@include font-face(MillerBWGL, #{$fds-lincoln-font-directory}/ LincolnMillerBWGL-Black);

// Lincoln ProximaNova
@include font-face(ProximaNova, #{$fds-lincoln-font-directory}/ Lincoln-ProximaNova-Light, 300);
@include font-face(ProximaNova, #{$fds-lincoln-font-directory}/ Lincoln-ProximaNova-Reg, 400);
@include font-face(ProximaNova, #{$fds-lincoln-font-directory}/ Lincoln-ProximaNova-Sbold, 500);
@include font-face(ProximaNova, #{$fds-lincoln-font-directory}/ Lincoln-ProximaNova-Bold, 900);

// Lincoln Icons
@include font-face(LincolnIcons, #{$fds-ford-icon-directory}/ lincoln-icons);
