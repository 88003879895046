.om-fmc__section {
  &--light {
    background-color: $fds-color--fourth;
    color: $fds-color--primary;
  }
  &--dark {
    background-color: $fds-color--primary;
    color: $fds-color--white;
  }
}

footer {
  background-color: $fds-color--white;
  [class*="fmc-divider--"] {
    border-color: $fds-color--gray2;
  }
}

footer.fds-bg--dark {
  background-color: $fds-color--primary;
  [class*="fmc-divider--"] {
    border-color: $fds-color--white;
  }
}


footer.fds-bg--light {
  background-color: $fds-color--gray1;
  [class*="fmc-divider--"] {
    border-color: $fds-color--primary;
  }
}

.fmc-divider--white {
  &[class*="fmc-divider--"] {
    border-color: rgba($fds-color--white, 1);
  }
  &.fmc-divider--bottom-full, &.fmc-divider--horizontal-full {
    &:before, &:after {
      background-color: rgba($fds-color--white, 1);
    }
  }
}

.fmc-divider--gray {
  &[class*="fmc-divider--"] {
    border-color: rgba($fds-color--black, 0.2);
  }
  &.fmc-divider--bottom-full, &.fmc-divider--horizontal-full {
    &:before, &:after {
      background-color: rgba($fds-color--black, 0.2);
    }
  }
}

.fmc-divider--gray1 {
  &[class*="fmc-divider--"] {
    border-color: rgba($fds-color--gray1, 1);
  }
  &.fmc-divider--bottom-full, &.fmc-divider--horizontal-full {
    &:before, &:after {
      background-color: rgba($fds-color--gray1, 1);
    }
  }
}

.fmc-divider--primary {
  &[class*="fmc-divider--"] {
    border-color: rgba($fds-color--primary, 1);
  }
  &.fmc-divider--bottom-full, &.fmc-divider--horizontal-full {
    &:before, &:after {
      background-color: rgba($fds-color--primary, 1);
    }
  }
}

.om-fmc-resource__box {
  border: 1px solid var(--fds-color--secondary);
}

.fmc-divider--top, .fmc-divider--top-full {
  &.fmc-divider--top--hide-xl {
    border-top-width: 0,
  }
  &.fmc-divider--top--hide-lg {
    @include media('<fds-lg') {
      border-top-width: 0,
    }
  }
  &.fmc-divider--top--hide-md {
    @include media('<=fds-md') {
      border-top-width: 0,
    }
  }
  &.fmc-divider--top--show-sm {
    @include media('<fds-md') {
      border-top-width: var(--divider-size),
    }
  }
}

.fmc-divider--bottom, .fmc-divider--bottom-full {
  &.fmc-divider--bottom--hide-xl {
    border-bottom-width: 0,
  }
  &.fmc-divider--bottom--hide-lg {
    @include media('<fds-lg') {
      border-bottom-width: 0,
    }
  }
  &.fmc-divider--bottom--hide-md {
    @include media('<=fds-md') {
      border-bottom-width: 0,
    }
  }
  &.fmc-divider--bottom--show-sm {
    @include media('<fds-md') {
      border-bottom-width: var(--divider-size),
    }
  }
}

.fmc-divider--left, .fmc-divider--left-full {
  &.fmc-divider--left--hide-xl {
    border-left-width: 0,
  }

  &.fmc-divider--left--hide-lg {
    @include media('<fds-lg') {
      border-left-width: 0,
    }
  }
  &.fmc-divider--left--hide-md {
    @include media('<=fds-md') {
      border-left-width: 0,
    }
  }
  &.fmc-divider--left--show-sm {
    @include media('<fds-md') {
      border-left-width: var(--divider-size),
    }
  }
}
.fmc-divider--vertical, .fmc-divider--vertical-full,
.fmc-divider--right, .fmc-divider--right-full {
  &.fmc-divider--top--right-xl {
    border-left-width: 0,
  }

  &.fmc-divider--right--hide-lg {
    @include media('<fds-lg') {
      border-right-width: 0,
    }
  }
  &.fmc-divider--right--hide-md {
    @include media('<=fds-md') {
      border-right-width: 0,
    }
  }
  &.fmc-divider--right--show-sm {
    @include media('<fds-md') {
      border-right-width: var(--divider-size),
    }
  }
}

.om-fmc-spacer {
  position: relative;
  z-index: inherit;
  &:after {
    content: "";
    position: absolute;
    z-index: 1;   
    width: 1px;
    height: fds-rem(24px);
    right: 0;
    top: calc(50% - 1rem);
    background-color: $fds-color--primary;
    line-height: normal;
    vertical-align: middle;
  }
  &.dark::after {
    background-color: $fds-color--gray4;
  }
}

.cvc-resources-cta-wrap {
  a.fmc-text-button {
    display: flex;
    span {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.4rem;
    }
  }
}

.fmc-billboard__scrim {
  opacity: 0.4;
}
input.input-no-control::-webkit-outer-spin-button,
input.input-no-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.input-no-control[type=number] {
  -moz-appearance: textfield;
}