// ================================
// Colors
// ================================

.fds-color__text--white {
    color: var(--fds-color--white);
}

.fds-color__text--black,
.fds-color__text--black > * {
    color: var(--fds-color--black) !important;
}

.fds-color__text--caution1,
.fds-color__text--caution1 > * {
    color: var(--fds-color--caution1) !important;
}

.fds-color__text--caution2,
.fds-color__text--caution2 > * {
    color: var(--fds-color--caution2) !important;
}

.fds-color__text--error1,
.fds-color__text--error1 > * {
    color: var(--fds-color--error1) !important;
}

.fds-color__text--error2,
.fds-color__text--error2 > * {
    color: var(--fds-color--error2) !important;
}

.fds-color__text--success1,
.fds-color__text--success1 > * {
    color: var(--fds-color--success1) !important;
}

.fds-color__text--success2,
.fds-color__text--success2 > * {
    color: var(--fds-color--success2) !important;
}

.fds-color__text--primary,
.fds-color__text--primary > * {
    color: var(--fds-color--primary) !important;
}

.fds-color__text--secondary,
.fds-color__text--secondary > * {
    color: var(--fds-color--secondary) !important;
}

.fds-color__text--tertiary,
.fds-color__text--tertiary > * {
    color: var(--fds-color--tertiary) !important;
}

.fds-color__text--fourth,
.fds-color__text--fourth > * {
    color: var(--fds-color--fourth) !important;
}

.fds-color__text--fifth,
.fds-color__text--fifth > * {
    color: var(--fds-color--fifth) !important;
}

.fds-color__text--gray1,
.fds-color__text--gray1 > * {
    color: var(--fds-color--gray1) !important;
}

.fds-color__text--gray2,
.fds-color__text--gray2 > * {
    color: var(--fds-color--gray2) !important;
}

.fds-color__text--gray3,
.fds-color__text--gray3 > * {
    color: var(--fds-color--gray3) !important;
}

.fds-color__text--gray4,
.fds-color__text--gray4 > * {
    color: var(--fds-color--gray4) !important;
}

.fds-color__text--gray5,
.fds-color__text--gray5 > * {
    color: var(--fds-color--gray5) !important;
}

.fds-color__text--disabled1,
.fds-color__text--disabled1 > * {
    color: var(--fds-color--disabled1) !important;
}

.fds-color__text--disabled2,
.fds-color__text--disabled2 > * {
    color: var(--fds-color--disabled2) !important;
}

.fds-color__text--disabled3,
.fds-color__text--disabled3 > * {
    color: var(--fds-color--disabled3) !important;
}

.fds-color__bg--dark {
    color: var(--fds-color--white);
}

.fds-color__bg--light {
    color: var(--fds-color--gray3);
}

.fds-color__bg--white {
    background-color: var(--fds-color--white);
}

.fds-color__bg--black {
    background-color: var(--fds-color--black);
}

.fds-color__bg--caution1 {
    background-color: var(--fds-color--caution1);
}

.fds-color__bg--caution2 {
    background-color: var(--fds-color--caution2);
}

.fds-color__bg--error1 {
    background-color: var(--fds-color--error1);
}

.fds-color__bg--error2 {
    background-color: var(--fds-color--error2);
}

.fds-color__bg--success1 {
    background-color: var(--fds-color--success1);
}

.fds-color__bg--success2 {
    background-color: var(--fds-color--success2);
}

.fds-color__bg--primary {
    background-color: var(--fds-color--primary);
}

.fds-color__bg--secondary {
    background-color: var(--fds-color--secondary);
}

.fds-color__bg--tertiary {
    background-color: var(--fds-color--tertiary);
}

.fds-color__bg--fourth {
    background-color: var(--fds-color--fourth);
}

.fds-color__bg--fifth {
    background-color: var(--fds-color--fifth);
}

.fds-color__bg--gray1 {
    background-color: var(--fds-color--gray1);
}

.fds-color__bg--gray2 {
    background-color: var(--fds-color--gray2);
}

.fds-color__bg--gray3 {
    background-color: var(--fds-color--gray3);
}

.fds-color__bg--gray4 {
    background-color: var(--fds-color--gray4);
}

.fds-color__bg--gray5 {
    background-color: var(--fds-color--gray5);
}

.fds-color__bg--disabled1 {
    background-color: var(--fds-color--disabled1);
}

.fds-color__bg--disabled2 {
    background-color: var(--fds-color--disabled2);
}

.fds-color__bg--disabled3 {
    background-color: var(--fds-color--disabled3);
}

// Typography

// Light Background
.fds-bg--light {
    & .fmc-type--heading1,
    & .fmc-type--heading2,
    & .fmc-type--heading3,
    & .fmc-type--heading4,
    & .fmc-type--heading5,
    & .fmc-type--heading6,
    & .fmc-type--subtitle1,
    & .fmc-type--subtitle2,
    & .fmc-type--body1,
    & .fmc-type--body2,
    & .fmc-type--content2 & a,
    & a:link,
    & a:active,
    & a:hover,
    & .fmc-type--content1,
    & .fmc-type--emphasize,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: var(--fds-color--primary);
    }

    & .fmc-type--heading1,
    & .fmc-type--heading2,
    & .fmc-type--heading3,
    & .fmc-type--heading4,
    & .fmc-type--heading5,
    & .fmc-type--heading6,
    & .fmc-type--subtitle1,
    & .fmc-type--subtitle2,
    & .fmc-type--body1,
    & .fmc-type--body2,
    & .fmc-type--content2,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        & a,
        & a:link,
        & a:active {
            outline-offset: fds-rem(10px);
            outline: 1px solid var(--fds-color--white-0);

            &:focus {
                outline-color: var(--fds-color--primary);
            }
        }

        & a:hover {
            color: var(--fds-color--secondary);
        }
    }
}

// Dark background
.fds-bg--dark {
    & a,
    & a:link,
    & a:active,
    & a:hover,
    & .fmc-type--heading1,
    & .fmc-type--heading2,
    & .fmc-type--heading3,
    & .fmc-type--heading4,
    & .fmc-type--heading5,
    & .fmc-type--heading6,
    & .fmc-type--subtitle1,
    & .fmc-type--subtitle2,
    & .fmc-type--body1,
    & .fmc-type--body2,
    & .fmc-type--content2,
    & .fmc-type--content1,
    & .fmc-type--emphasize,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: var(--fds-color--white);

        & a,
        & a:link,
        & a:active,
        & a:hover {
            outline-offset: fds-rem(10px);
            outline: 1px solid var(--fds-color--white-0);

            &:focus {
                outline-color: var(--fds-color--white);
            }
        }
    }
}

// Not specified
.fmc-type--heading1,
.fmc-type--heading2,
.fmc-type--heading3,
.fmc-type--heading4,
.fmc-type--heading5,
.fmc-type--heading6,
.fmc-type--subtitle1,
.fmc-type--subtitle2,
.fmc-type--body1,
.fmc-type--body2,
.fmc-type--content2,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
a:link,
a:active,
.fmc-type--content1,
.fmc-type--emphasize {
    color: var(--fds-color--primary);
}

a:hover {
    color: var(--fds-color--secondary);
}

.fmc-type--heading1,
.fmc-type--heading2,
.fmc-type--heading3,
.fmc-type--heading4,
.fmc-type--heading5,
.fmc-type--heading6,
.fmc-type--subtitle1,
.fmc-type--subtitle2,
.fmc-type--body1,
.fmc-type--body2,
.fmc-type--content2,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    & a,
    & a:link,
    & a:active {
        outline-offset: fds-rem(10px);
        outline: 1px solid var(--fds-color--white-0);

        &:focus {
            outline-color: var(--fds-color--primary);
        }
    }
}
