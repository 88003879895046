.fmc-dialog {
    --content-width: 100%;
    --content-margin: 0;
    --content-padding-top: #{fds-rem(56px)};
    --content-padding-bottom: #{fds-rem(32px)};
    --content-padding-x: #{fds-rem(20px)};
    --close-btn-pos-x: var(--content-padding-x);
    --close-btn-pos-y: calc(var(--content-padding-top) / 2);
    --close-btn-font-size: #{fds-rem(24px)};
    --close-btn-transform: translateY(-50%);
    --background-color: var(--fds-color--tertiary-70);
    --box-shadow: var(--fmc-elevation__box-shadow--layer3);

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: var(--background-color);

    @include fds-set-brand(lincoln) {
        --background-color: rgba(33, 42, 43, 0.8);
        --box-shadow: none;

        @include media('>=fds-md') {
            --content-width: 75vw;
        }
    }

    @include media('>=fds-sm') {
        --content-width: 85vw;
        --content-margin: #{fds-rem(96px auto)};
    }

    @include media('>=fds-md') {
        --content-width: 55vw;
        --content-padding-x: #{fds-rem(32px)};
        --close-btn-pos-x: var(--close-btn-pos-y);
        --close-btn-font-size: #{fds-rem(16px)};
        --close-btn-transform: translate(50%, -50%);
    }

    &.fmc-dialog--opened {
        display: block;
    }

    img {
        max-width: 100%;
    }

    .fmc-button::after {
        box-shadow: none;
    }

    .fmc-dialog__content {
        position: relative;
        width: var(--content-width);
        margin: var(--content-margin);
        padding: var(--content-padding-top) var(--content-padding-x) var(--content-padding-bottom);
        background-color: var(--fds-color--white);
        box-shadow: var(--box-shadow);
    }

    .fmc-dialog__content--no-padding {
        padding: 0;
    }

    .fmc-dialog__close {
        position: absolute;
        top: var(--close-btn-pos-y);
        right: var(--close-btn-pos-x);
        font-size: var(--close-btn-font-size);
        color: var(--fds-color--primary);
        transform: var(--close-btn-transform);
        outline: 1px solid transparent;
        outline-offset: #{fds-rem(4px)};
        transition: outline-color var(--fmc-transition-duration);

        &:focus-visible {
            outline-color: var(--fds-color--primary);
        }
    }
}
