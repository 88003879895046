.fmc-fabs {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;

    &.fmc-fabs--fixed {
        align-items: end;
        position: fixed;
        bottom: 1.6rem;
        right: 1.6rem;
        width: auto;
    }
}

.fmc-fab {
    --fab-size: 4rem;
    --icon-transform: translateX(8px);
    --text-size: 1.8rem;
    --text-color: var(--fds-color--white);
    --text-opacity: 0;
    --padding: 0;
    --max-width: var(--fab-size);
    --border: 1px solid var(--fds-color--white);
    --background-color: var(--fds-color--primary);
    --box-shadow: var(--fmc-elevation__box-shadow--layer2);

    color: var(--text-color);
    font-size: var(--text-size);
    display: inline-flex;
    gap: 1.6rem;
    padding: var(--padding);
    position: relative;
    min-height: var(--fab-size);
    min-width: var(--fab-size);
    max-width: var(--max-width);
    align-items: center;
    justify-content: flex-start;
    transition: all 0.3s ease-in-out;

    @include fds-set-brand(lincoln) {
        --fab-size: 4.8rem;
        --text-color: var(--fds-color--primary);
        --border: 1px solid var(--fds-color--disabled2);
        --background-color: var(--fds-color--white);

        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;
        max-width: none;
    }

    &::before {
        content: '';
        border-radius: 2.4rem;
        border: var(--border);
        background-color: var(--background-color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        box-shadow: var(--box-shadow);
        transition: background-color 0.3s ease-in-out;

        @include fds-set-brand(lincoln) {
            --box-shadow: none;

            width: var(--fab-size);
            height: var(--fab-size);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &:focus-visible:not([disabled])::before {
        outline: var(--fds-outline);
        outline-offset: 0.8rem;
    }

    @include fds-set-hover {
        &:not([disabled]) {
            --background-color: var(--fds-color--secondary);
            --box-shadow: var(--fmc-elevation__box-shadow--layer3);
            --icon-transform: translateX(0);
            --text-opacity: 1;
            --padding: 0 4.2rem;
            --max-width: 99rem;

            @include fds-set-brand(lincoln) {
                --padding: 0;
                --background-color: var(--fds-color--white);
            }
        }
    }

    @include fds-set-disabled {
        --background-color: var(--fds-color--disabled2);

        cursor: not-allowed;
    }

    &:active {
        &::before {
            @include fds-set-brand(lincoln) {
                --background-color: var(--fds-color--primary);
            }
        }

        .fds-icon {
            --text-color: var(--fds-color--white);
        }
    }

    .fds-icon,
    .fmc-fab__text {
        color: var(--text-color);
        z-index: 1;
    }

    .fmc-fab__text {
        white-space: nowrap;
        pointer-events: none;
        opacity: var(--text-opacity);
        transition: opacity 0.2s ease-in-out;

        @include fds-set-brand(lincoln) {
            font-size: 1.6rem;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .fds-icon {
        transform: var(--icon-transform);
        transition: transform 0.3s ease-in-out;

        @include fds-set-brand(lincoln) {
            transform: none;
        }

        &::before {
            @include fds-set-brand(lincoln) {
                content: $fds-font--ford-icons__long-arrow-back;
                height: var(--fab-size);
                width: var(--fab-size);
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(90deg);
            }
        }
    }
}
