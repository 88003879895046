.fds-radio {
    display: flex;
    position: relative;
    height: fds-rem(36px);

    &.fds-radio--focused {
        & .fds-radio__circle-outer {
            outline: 1px solid var(--fds-color--primary);
            outline-offset: fds-rem(5px);
        }
    }

    &:not(.fds-radio--disabled) {
        & .fds-radio__label-wrapper,
        & .fds-radio__label-wrapper .fds-radio__label {
            cursor: pointer;
        }
    }

    & .fds-radio__input {
        opacity: 0;
        width: fds-rem(16px);
        height: fds-rem(16px);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;

        &:checked + .fds-radio__label-wrapper {
            & .fds-radio__circle-outer {
                border-color: var(--fds-color--primary);
                background-color: var(--fds-color--white);
            }

            & .fds-radio__circle-inner {
                display: block;
                background-color: var(--fds-color--primary);
            }
        }
    }

    &.fds-radio--disabled {
        & .fds-radio__input {
            cursor: not-allowed;
        }

        & .fds-radio__label-wrapper {
            & .fds-radio__circle-outer,
            & .fmc-type--radio-label {
                cursor: not-allowed;
                background-color: var(--fds-color--disabled3);
                color: var(--fds-color--white);
            }
        }
    }

    & .fds-radio__label-wrapper {
        position: absolute;
        left: 0;
        display: flex;
        align-items: center;
        height: fds-rem(26px);
        margin-bottom: fds-rem(10px);
    }

    & .fds-radio__label {
        display: flex;
        align-items: center;
    }

    & .fds-radio__circle-outer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fds-rem(16px);
        height: fds-rem(16px);
        border-style: solid;
        border-width: fds-rem(2px);
        border-radius: 50%;
        background-color: transparent;
        border-color: var(--fds-color--gray2);
    }

    & .fds-radio__circle-inner {
        display: none;
        height: fds-rem(8px);
        width: fds-rem(8px);
        border-radius: 50%;
    }

    & .fmc-type--radio-label {
        @include fds-get-component-typeset('radio-label');

        margin-left: fds-rem(20px);
        padding: fds-rem(2px);
        color: var(--fds-color--gray3);
    }
}
