// ================================
// Tools: Set disabled state
// ================================

// Sets disabled states

@mixin fds-set-disabled() {
    &[disabled],
    &[aria-disabled='true'] {
        @content;
    }
}
