// ================================
// Tools: Columns
// ================================

@mixin fds-columns($columns...) {
    .fds-flex__col-auto {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .fds-flex__col-variable {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    @each $i in $columns {
        @for $n from 1 through $i {
            .fds-flex__col-#{$n} {
                flex-basis: ($n / $i) * 100%;
                max-width: ($n / $i) * 100%;
            }
        }
    }

    @each $breakpoint in map-keys($fds-breakpoints) {
        @include media(#{'>=' + $breakpoint}) {
            .#{$breakpoint}\:fds-flex__col-auto {
                flex-grow: 1;
                flex-basis: 0;
                max-width: 100%;
            }

            .#{$breakpoint}\:fds-flex__col-variable {
                flex: 0 0 auto;
                width: auto;
                max-width: none;
            }

            @each $i in $columns {
                @for $n from 1 through $i {
                    .#{$breakpoint}\:fds-flex__col-#{$n} {
                        flex-basis: ($n / $i) * 100%;
                        max-width: ($n / $i) * 100%;
                    }
                }
            }
        }
    }
}

// Generate offset classes for each breakpoint
@mixin fds-columns-offsets($columns...) {
    @each $i in $columns {
        @for $n from 1 through ($i - 1) {
            .fds-flex__col-offset-#{$n} {
                margin-left: ($n / $i) * 100%;
            }
        }
    }

    @each $breakpoint in map-keys($fds-breakpoints) {
        @include media(#{'>=' + $breakpoint}) {
            @each $i in $columns {
                @for $n from 1 through ($i - 1) {
                    .#{$breakpoint}\:fds-flex__col-offset-#{$n} {
                        margin-left: ($n / $i) * 100%;
                    }
                }
            }
        }
    }
}

// Generate flexbox helper classes for each breakpoint
@mixin fds-columns-helpers() {
    // Utilities for controlling how flex items are positioned along a container's cross axis.
    .fds-flex {
        display: flex;
    }

    .fds-flex__items--start {
        align-items: flex-start;
    }

    .fds-flex__items--center {
        align-items: center;
    }

    .fds-flex__items--end {
        align-items: flex-end;
    }

    .fds-flex__items--baseline {
        align-items: baseline;
    }

    // Utilities for controlling how lines are positioned in multi-line flex containers.
    .fds-flex__content--start {
        align-content: flex-start;
    }

    .fds-flex__content--center {
        align-content: center;
    }

    .fds-flex__content--end {
        align-content: flex-end;
    }

    .fds-flex__content--between {
        align-content: space-between;
    }

    .fds-flex__content--around {
        align-content: space-around;
    }

    // Utilities for controlling how an individual flex item is positioned along its container's cross axis.
    .fds-flex__self--auto {
        align-self: auto;
    }

    .fds-flex__self--start {
        align-self: flex-start;
    }

    .fds-flex__self--center {
        align-self: center;
    }

    .fds-flex__self--end {
        align-self: flex-end;
    }

    .fds-flex__self--stretch {
        align-self: stretch;
    }

    // Utilities for controlling flex items are positioned along a container's main axis.
    .fds-flex__justify--start {
        justify-content: flex-start;
    }

    .fds-flex__justify--center {
        justify-content: center;
    }

    .fds-flex__justify--end {
        justify-content: flex-end;
    }

    .fds-flex__justify--between {
        justify-content: space-between;
    }

    .fds-flex__justify--around {
        justify-content: space-around;
    }

    .fds-flex__justify--evenly {
        justify-content: space-evenly;
    }

    // Utilities to help order flex-items
    .fds-flex__order-first {
        order: -1;
    }

    .fds-flex__order-last {
        order: 1;
    }

    @each $breakpoint in map-keys($fds-breakpoints) {
        @include media(#{'>=' + $breakpoint}) {
            // Utilities for controlling how flex items are positioned along a container's cross axis
            .#{$breakpoint}\:fds-flex__items--start {
                align-items: flex-start;
            }

            .#{$breakpoint}\:fds-flex__items--center {
                align-items: center;
            }

            .#{$breakpoint}\:fds-flex__items--end {
                align-items: flex-end;
            }

            .#{$breakpoint}\:fds-flex__items--baseline {
                align-items: baseline;
            }

            // Utilities for controlling how lines are positioned in multi-line flex containers
            .#{$breakpoint}\:fds-flex__content--start {
                align-content: flex-start;
            }

            .#{$breakpoint}\:fds-flex__content--center {
                align-content: center;
            }

            .#{$breakpoint}\:fds-flex__content--end {
                align-content: flex-end;
            }

            .#{$breakpoint}\:fds-flex__content--between {
                align-content: space-between;
            }

            .#{$breakpoint}\:fds-flex__content--around {
                align-content: space-around;
            }

            // Utilities for controlling how an individual flex item is positioned along its container's cross axis
            .#{$breakpoint}\:fds-flex__self--auto {
                align-self: auto;
            }

            .#{$breakpoint}\:fds-flex__self--start {
                align-self: flex-start;
            }

            .#{$breakpoint}\:fds-flex__self--center {
                align-self: center;
            }

            .#{$breakpoint}\:fds-flex__self--end {
                align-self: flex-end;
            }

            .#{$breakpoint}\:fds-flex__self--stretch {
                align-self: stretch;
            }

            // Utilities for controlling flex items are positioned along a container's main axis
            .#{$breakpoint}\:fds-flex__justify--start {
                justify-content: flex-start;
            }

            .#{$breakpoint}\:fds-flex__justify--center {
                justify-content: center;
            }

            .#{$breakpoint}\:fds-flex__justify--end {
                justify-content: flex-end;
            }

            .#{$breakpoint}\:fds-flex__justify--between {
                justify-content: space-between;
            }

            .#{$breakpoint}\:fds-flex__justify--around {
                justify-content: space-around;
            }

            .#{$breakpoint}\:fds-flex__justify--evenly {
                justify-content: space-around;
            }

            // Utilities to help order flex-items
            .#{$breakpoint}\:fds-flex__order--first {
                order: -1;
            }

            .#{$breakpoint}\:fds-flex__order--last {
                order: 1;
            }

            .#{$breakpoint}\:fds-flex--start-#{$breakpoint} {
                justify-content: flex-start;
            }

            .#{$breakpoint}\:fds-flex--center-#{$breakpoint} {
                justify-content: center;
            }

            .#{$breakpoint}\:fds-flex--end-#{$breakpoint} {
                justify-content: flex-end;
            }

            .#{$breakpoint}\:fds-flex--top-#{$breakpoint} {
                align-items: flex-start;
            }

            .#{$breakpoint}\:fds-flex--middle-#{$breakpoint} {
                align-items: center;
            }

            .#{$breakpoint}\:fds-flex--bottom-#{$breakpoint} {
                align-items: flex-end;
            }

            .#{$breakpoint}\:fds-flex--around-#{$breakpoint} {
                justify-content: space-around;
            }

            .#{$breakpoint}\:fds-flex--between-#{$breakpoint} {
                justify-content: space-between;
            }

            .#{$breakpoint}\:fds-flex--first-#{$breakpoint} {
                order: -1;
            }

            .#{$breakpoint}\:fds-flex--last-#{$breakpoint} {
                order: 1;
            }
        }
    }
}
