// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

// Mixins:
// returns the lower grid boundary or null if the smallest grid is selected
@function fds-layout-grid-breakpoint-min($size) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $min: map-get($fds-layout-grid-breakpoints, $size);
    @return if($min > 0, $min, null);
}

// returns the upper grid boundary or null if the largest grid is selected
@function fds-layout-grid-breakpoint-max($size) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $names: map-keys($fds-layout-grid-columns);
    $n: index($names, $size);
    $prev: if($n > 1, nth($names, $n - 1), null);
    @return if($prev, (fds-layout-grid-breakpoint-min($prev) - 1px), null);
}

// Private mixins, meant for internal use.
@mixin fds-layout-grid-media-query_($size) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $min: fds-layout-grid-breakpoint-min($size);
    $max: fds-layout-grid-breakpoint-max($size);
    @if $min == null and $max != null {
        // Phone
        @media (max-width: $max) {
            @content;
        }
    } @else if $min != null and $max != null {
        // Tablet
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $min != null and $max == null {
        // Desktop
        @media (min-width: $min) {
            @content;
        }
    } @else {
        // Fallback - no breakpoints defined
        @content;
    }
}

@mixin fds-layout-flexbox-cell-span_($size, $span, $gutter) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $percentage: 100%;
    $column-diff: calc(12 - #{$span});
    $padding: calc(var(--fds-layout-grid-padding-#{$size}) * 2);
    width: calc(
        #{$percentage} - var(--fds-layout-grid-column-width-#{$size}) * #{$column-diff} - #{$padding}
    );
}

@mixin fds-layout-grid-cell-span_($size, $span, $gutter) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $percent: percentage($span / map-get($fds-layout-grid-columns, $size));
    @if $percent > 100% {
        $percent: 100%;
    }
    width: calc(#{$percent} - #{$gutter});
    width: calc(#{$percent} - var(--fds-layout-grid-gutter-#{$size}, #{$gutter}));
    @supports (display: grid) {
        width: auto;
        grid-column-end: span min($span, map-get($fds-layout-grid-columns, $size));
    }
}

// Public mixins, meant for developer usage.
@mixin fds-layout-grid($size, $padding, $margin, $max-width: null) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0
        calc(
            var(--fds-layout-grid-margin-#{$size}) +
                (var(--fds-layout-grid-padding-#{$size}) - var(--fds-layout-grid-margin-#{$size}))
        );
    @if $max-width {
        max-width: $max-width;
    }
}

@mixin fds-layout-grid-inner($size, $margin, $gutter) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -$gutter / 2;
    margin: calc(var(--fds-layout-grid-gutter-#{$size}, #{$gutter}) / 2 * -1);
    @supports (display: grid) {
        display: grid;
        margin: 0;
        grid-gap: $gutter;
        grid-gap: var(--fds-layout-grid-gutter-#{$size}, $gutter);
        grid-template-columns: repeat(map-get($fds-layout-grid-columns, $size), minmax(0, 1fr));
    }
}

@mixin fds-layout-grid-cell($size, $default-span, $gutter) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    @include fds-layout-grid-cell-span_($size, $default-span, $gutter);
    box-sizing: border-box;
    margin: $gutter / 2;
    margin: calc(var(--fds-layout-grid-gutter-#{$size}, #{$gutter}) / 2);
    @supports (display: grid) {
        margin: 0;
    }
}

@mixin fds-layout-flexbox-cell($size, $default-span, $gutter) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    @include fds-layout-flexbox-cell-span_($size, $default-span, $gutter);
}

@mixin fds-layout-grid-cell-order($order) {
    order: $order;
}

@mixin fds-layout-grid-cell-align($position) {
    @if $position == 'top' {
        align-self: flex-start;
        @supports (display: grid) {
            align-self: flex-start;
        }
    }
    @if $position == 'middle' {
        align-self: center;
    }
    @if $position == 'bottom' {
        align-self: flex-end;
        @supports (display: grid) {
            align-self: end;
        }
    }
    @if $position == 'stretch' {
        align-self: stretch;
    }
}

@mixin fds-layout-grid-fixed-column-width($size, $margin, $gutter, $column-width) {
    @if not map-has-key($fds-layout-grid-columns, $size) {
        @error "Invalid style specified! Choose one of #{map-keys($fds-layout-grid-columns)}";
    }
    $columnCount: map-get($fds-layout-grid-columns, $size);
    $gutter-number: $columnCount - 1;
    $margin-number: 2;
    width: $column-width * $columnCount + $gutter * $gutter-number + $margin * $margin-number;
    width: calc(
        var(--fds-layout-grid-column-width-#{$size}, #{$column-width}) * #{$columnCount} + var(
                --fds-layout-grid-gutter-#{$size},
                #{$gutter}
            ) * #{$gutter-number} + var(--fds-layout-grid-margin-#{$size}, #{$margin}) * #{$margin-number}
    );
}

.fds-layout-grid {
    @each $size in map-keys($fds-layout-grid-columns) {
        @include fds-layout-grid-media-query_($size) {
            $padding: map-get($fds-layout-grid-padding, $size);
            $margin: map-get($fds-layout-grid-default-margin, $size);
            @include fds-layout-grid($size, $padding, $margin, $fds-layout-grid-max-width);
        }
    }
}

.fds-layout-grid__inner {
    @each $size in map-keys($fds-layout-grid-columns) {
        @include fds-layout-grid-media-query_($size) {
            $margin: map-get($fds-layout-grid-default-margin, $size);
            $gutter: map-get($fds-layout-grid-default-gutter, $size);
            @include fds-layout-grid-inner($size, $margin, $gutter);
        }
    }
}

.fds-layout-grid__cell {
    // select the upper breakpoint
    $upper-breakpoint: nth(map-keys($fds-layout-grid-columns), 1);
    @each $size in map-keys($fds-layout-grid-columns) {
        @include fds-layout-grid-media-query_($size) {
            $gutter: map-get($fds-layout-grid-default-gutter, $size);
            @include fds-layout-grid-cell($size, $fds-layout-grid-default-column-span, $gutter);
            @for $span from 1 through map-get($fds-layout-grid-columns, $upper-breakpoint) {
                // Span classes.
                // stylelint-disable max-nesting-depth
                @at-root .fds-layout-grid__cell--span-#{$span},
                    .fds-layout-grid__cell--span-#{$span}-#{$size} {
                    @include fds-layout-grid-cell-span_($size, $span, $gutter);
                }
                // stylelint-enable max-nesting-depth
            }
        }
    }

    // Order override classes.
    @for $i from 1 through map-get($fds-layout-grid-columns, $upper-breakpoint) {
        &--order-#{$i} {
            @include fds-layout-grid-cell-order($i);
        }
    }

    // Alignment classes.
    &--align-top {
        @include fds-layout-grid-cell-align(top);
    }
    &--align-middle {
        @include fds-layout-grid-cell-align(middle);
    }
    &--align-bottom {
        @include fds-layout-grid-cell-align(bottom);
    }
}

.fds-layout-flexbox__cell {
    // select the upper breakpoint
    $upper-breakpoint: nth(map-keys($fds-layout-grid-columns), 1);
    @each $size in map-keys($fds-layout-grid-columns) {
        @include fds-layout-grid-media-query_($size) {
            $gutter: map-get($fds-layout-grid-default-gutter, $size);
            @include fds-layout-flexbox-cell($size, $fds-layout-grid-default-column-span, $gutter);
            @for $span from 1 through map-get($fds-layout-grid-columns, $upper-breakpoint) {
                // Span classes.
                // stylelint-disable max-nesting-depth
                @at-root .fds-layout-flexbox__cell--span-#{$span},
                    .fds-layout-flexbox__cell--span-#{$span}-#{$size} {
                    @include fds-layout-flexbox-cell-span_($size, $span, $gutter);
                }
                // stylelint-enable max-nesting-depth
            }
        }
    }
}

.fds-layout-grid--fixed-column-width {
    @each $size in map-keys($fds-layout-grid-columns) {
        @include fds-layout-grid-media-query_($size) {
            $margin: map-get($fds-layout-grid-default-margin, $size);
            $gutter: map-get($fds-layout-grid-default-gutter, $size);
            $column-width: map-get($fds-layout-grid-column-width, $size);
            @include fds-layout-grid-fixed-column-width($size, $margin, $gutter, $column-width);
        }
    }
}

.fds-layout-grid--align-left {
    margin-right: auto;
    margin-left: 0;
}

.fds-layout-grid--align-right {
    margin-right: 0;
    margin-left: auto;
}
