.om-fmc-tabs.fmc-tabs {
  --tab-border-color: var(--fds-color--secondary);
  .fmc-tabs__tablist-wrapper {
    &:before {
      content: "";
      height: 1px;
      position: absolute;
      background-color: var(--fds-color--gray2);
      bottom: calc(3.2rem + 1px);
      width: 100%;
      left: 0;
      z-index: 0;
    }
    .om-tabs__tab.fmc-tabs__tab {
        color: $fds-color--gray2;
        font-weight: var(--fmc-type__weight-normal);
        font-size: 1.6rem;
        &.fmc-tabs__tab--active {
          color: $fds-color--secondary;
          font-weight: var(--fmc-type__weight-bold);
      }
    }
    .fmc-tabs__tablist {
      margin-left: 2rem;
      margin-right: 0;  
      .om-tabs__tab-border.fmc-tabs__tab-border {
        display: none;
      }

      .fmc-tabs__tab {
        @extend .fmc-type--subtitle1;
        @extend .fds-weight--medium;
        @extend .fds-stretch--normal;
      }
    }
  }
}