.fds-color__bg--light {
    --fmc-list-style-ul--level-1: url('#{$fds-ford-icon-directory}/list/light-unordered-level-1.svg');
    --fmc-list-style-ul--level-2: url('#{$fds-ford-icon-directory}/list/light-unordered-level-2.svg');
    --fmc-list-style-ul--level-3: url('#{$fds-ford-icon-directory}/list/light-unordered-level-3.svg');
}

.fds-color__bg--dark {
    --fmc-list-style-ul--level-1: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-1.svg');
    --fmc-list-style-ul--level-2: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-2.svg');
    --fmc-list-style-ul--level-3: url('#{$fds-ford-icon-directory}/list/dark-unordered-level-3.svg');
}

.fds-color__bg--light,
.fds-color__bg--dark {
    @include fds-set-brand(lincoln) {
        --fmc-list-style-ul--level-1: disc;
        --fmc-list-style-ul--level-2: '\2013   '; // NOTE: 3 trailing spaces is for indentation
        --fmc-list-style-ul--level-3: circle;
    }
}

ul.fmc-list {
    list-style: var(--fmc-list-style-ul--level-1);
}

.fmc-list ul.fmc-list {
    list-style: var(--fmc-list-style-ul--level-2);
}

.fmc-list .fmc-list ul.fmc-list {
    list-style: var(--fmc-list-style-ul--level-3);
}

ol.fmc-list {
    list-style-type: decimal;
}

.fmc-list ol.fmc-list {
    list-style-type: lower-alpha;
}

.fmc-list .fmc-list ol.fmc-list {
    list-style-type: lower-roman;
}

.fmc-list {
    --list-indent: #{fds-rem(32px)};
}

// stylelint-disable no-duplicate-selectors
ol.fmc-list {
    --bullet-outdent: #{fds-rem(8px)};
}

ul.fmc-list {
    --bullet-outdent: #{fds-rem(10px)};
}

.fmc-list li {
    @extend %fmc-type--body1;
    margin-left: calc(var(--list-indent) - var(--bullet-outdent));
    padding-left: var(--bullet-outdent);
    padding-top: fds-rem(8px);
}

.fmc-list {
    margin-left: fds-rem(16px);
}

.fmc-list .fmc-list {
    margin-left: 0;
}
