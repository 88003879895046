.fmc-file-upload {
    --upload-height: #{fds-rem(128px)};
    --upload-width--xs: 100%;
    --upload-width--sm: #{fds-rem(330px)};
    --upload-width--md: #{fds-rem(406px)};
    --upload-width: var(--upload-width--xs);

    %fmc-file-upload__link {
        text-decoration: underline;
        color: var(--fds-color--primary);
        cursor: pointer;
    }

    %fmc-file-upload__link--hover {
        color: var(--fds-color--secondary);
    }

    %fmc-file-upload__link--focus {
        color: var(--fds-color--secondary);
        outline: var(--fds-outline);
        outline-offset: var(--fds-outline-offset);
    }

    width: var(--upload-width);

    @include media('>=fds-sm') {
        --upload-width: var(--upload-width--sm);
    }

    @include media('>=fds-md') {
        --upload-width: var(--upload-width--md);
    }

    label {
        position: relative;
        z-index: 0; // establish stacking context
        display: flex;
        width: 100%;
    }

    input[type='file' i] {
        position: absolute;
        z-index: 20; // z-index is important to make drag-and-drop work -- <input> must be on top.
        height: 100%;
        width: 100%;
        opacity: 0;
    }

    .fmc-file-upload__text {
        z-index: 10;
        width: 100%;
        padding: 0;
        color: var(--fds-color--gray3);

        @include media('>=fds-sm') {
            border: 1px dashed var(--fds-color--gray2);
            padding: fds-rem(16px 7px);
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            text-align: center;
            gap: fds-rem(8px 16px);
        }

        @include media('>=fds-md') {
            flex-flow: row nowrap;
            justify-content: flex-start;
            text-align: start;
        }
    }

    .fmc-file-upload__drag-drop {
        display: none;

        @include media('>=fds-sm') {
            display: inline;
        }
    }

    // Select text is supposed to behave as if it were an anchor tag
    .fmc-file-upload__select {
        @extend %fmc-file-upload__link;
    }

    input[type='file' i]:hover + .fmc-file-upload__text .fmc-file-upload__select {
        @extend %fmc-file-upload__link--hover;
    }

    input[type='file' i]:focus-visible + .fmc-file-upload__text .fmc-file-upload__select {
        @extend %fmc-file-upload__link--focus;
    }

    .fmc-file-upload__label,
    .fmc-file-upload__caption,
    .fmc-file-upload__file {
        @extend %fmc-type--content2;
        color: var(--fds-color--gray3);
        @include media('>=fds-sm') {
            padding-left: fds-rem(8px);
        }
    }

    .fmc-file-upload__label {
        padding-bottom: fds-rem(8px);
    }

    .fmc-file-upload__caption {
        padding-top: fds-rem(8px);
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;

        @include media('>=fds-sm') {
            display: block;
        }
    }

    .fmc-file-upload__files {
        padding-top: fds-rem(8px);
    }

    .fmc-file-upload__file {
        padding-top: fds-rem(24px);
        min-width: 0;
        display: grid;
        justify-items: start;
        grid:
            'file-name   status'
            'progress    progress'
            / 1fr auto;

        @include media('>=fds-sm') {
            grid:
                'file-name   status   progress'
                / 1fr auto auto;
        }
    }

    .fmc-file-upload__file-name {
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        grid-area: file-name;
        color: var(--fds-color--primary);
    }

    .fmc-file-upload__file-name .fds-icon {
        font-size: inherit;
        vertical-align: baseline;
    }

    .fmc-file-upload__action,
    .fmc-file-upload__failed {
        grid-area: status;
        margin-left: fds-rem(16px);
        white-space: nowrap;
        display: flex;

        :not(:first-child) {
            margin-left: fds-rem(24px);
        }
    }

    .fmc-file-upload__action-button {
        @extend %fmc-file-upload__link;

        &:hover {
            @extend %fmc-file-upload__link--hover;
        }

        &:focus {
            @extend %fmc-file-upload__link--focus;
        }
    }

    .fmc-file-upload__progress {
        width: 100%;
        grid-area: progress;
        margin-top: fds-rem(8px);
        @include media('>=fds-sm') {
            margin-top: 0;
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .fmc-file-upload__progress-percent {
        margin-right: fds-rem(8px);
        width: fds-rem(32px); // what if the font width changes...?
        text-align: end;
        @include media('>=fds-sm') {
            margin-left: fds-rem(16px);
        }
    }

    .fmc-file-upload__progress-bar {
        flex-grow: 1;
        @include media('>=fds-sm') {
            width: fds-rem(88px);
        }
        @include media('>=fds-md') {
            width: fds-rem(168px);
        }
    }

    .fmc-file-upload__progress--confirm-cancel {
        .fmc-file-upload__progress-percent,
        .fmc-file-upload__progress-bar {
            @include media('>=fds-sm') {
                display: none;
            }
        }
    }
}
