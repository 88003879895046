// ================================
// Utilities: Display
// ================================

$fds-displays: (
    block: block,
    inline: inline,
    inlineblock: inline-block,
    flex: flex,
    inlineflex: inline-flex,
) !default;

@include fds-set-selectors($fds-displays, 'display', 'fds-display--');
