// ================================
// Typography
// ================================

// Sets
$om-fds-type-sets: (
  'heading1': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(40px)},
        --fmc-type-leading: #{$fds-line-height__heading1},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(50px)},
        --fmc-type-leading: #{fds-rem(60px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(75px)},
        --fmc-type-leading: #{fds-rem(88px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: var(--fmc-font--miller),
        --fmc-type-size: #{fds-rem(40px)},
        --fmc-type-leading: 1.625,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(50px)},
        --fmc-type-leading: 1.12
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(66px)},
        --fmc-type-leading: 1.16
      )
    )
  ),
  'heading2': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(36px)},
        --fmc-type-leading: #{$fds-line-height__heading2},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(44px)},
        --fmc-type-leading: #{fds-rem(52px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(60px)},
        --fmc-type-leading: #{fds-rem(72px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: var(--fmc-font--miller),
        --fmc-type-size: #{fds-rem(34px)},
        --fmc-type-leading: 1.625,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(47px)},
        --fmc-type-leading: 1.12
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(60px)},
        --fmc-type-leading: 1.16
      )
    )
  ),
  'heading3': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(32px)},
        --fmc-type-leading: #{$fds-line-height__heading3},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(36px)},
        --fmc-type-leading: #{fds-rem(44px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(44px)},
        --fmc-type-leading: #{fds-rem(52px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(24px)},
        --fmc-type-leading: 1.2,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(32px)},
        --fmc-type-leading: 1.2
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(40px)},
        --fmc-type-leading: 1.2
      )
    )
  ),
  'heading4': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(28px)},
        --fmc-type-leading: #{$fds-line-height__heading4},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(32px)},
        --fmc-type-leading: #{fds-rem(44px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(34px)},
        --fmc-type-leading: #{fds-rem(40px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(19px)},
        --fmc-type-leading: 1.68,
        --fmc-type-tracking: #{fds-rem(2px)},
        --fmc-type-transform: uppercase
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(25px)},
        --fmc-type-leading: 1.6,
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(32px)},
        --fmc-type-leading: 1.5,
      )
    )
  ),
  'heading5': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(24px)},
        --fmc-type-leading: #{$fds-line-height__heading5},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(28px)},
        --fmc-type-leading: #{fds-rem(36px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(32px)},
        --fmc-type-leading: #{fds-rem(40px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(15px)},
        --fmc-type-leading: 1.17,
        --fmc-type-tracking: #{fds-rem(2px)},
        --fmc-type-transform: uppercase
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(19px)},
        --fmc-type-leading: 1.36,
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(24px)},
        --fmc-type-leading: 1.33,
      )
    )
  ),
  'heading6': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(20px)},
        --fmc-type-leading: #{$fds-line-height__heading6},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(22px)},
        --fmc-type-leading: #{fds-rem(28px)}
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(24px)},
        --fmc-type-leading: #{fds-rem(28px)}
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(10px)},
        --fmc-type-leading: 1.4,
        --fmc-type-tracking: #{fds-rem(1.25px)},
        --fmc-type-transform: uppercase
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(13px)},
        --fmc-type-leading: 1.3,
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(17px)},
        --fmc-type-leading: 1.17,
      )
    )
  ),
  'subtitle1': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__subtitle1},
        --fmc-type-leading: #{$fds-line-height__subtitle1},
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(18px)},
        --fmc-type-leading: 1.44,
        --fmc-type-tracking: 0,
        --fmc-type-transform: initial,
        --fmc-type-stretch: initial
      )
    )
  ),
  'subtitle2': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__subtitle2},
        --fmc-type-leading: #{$fds-line-height__subtitle2},
        --fmc-type-tracking: #{fds-rem(1px)},
        --fmc-type-transform: uppercase,
        --fmc-type-stretch: condensed,
        font-family: var(--fmc-type-family),
        font-stretch: var(--fmc-type-stretch),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(15px)},
        --fmc-type-leading: 1.2,
        --fmc-type-tracking: #{fds-rem(1.5px)},
        --fmc-type-transform: uppercase,
        --fmc-type-stretch: initial
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(16px)},
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(17px)},
      )
    )
  ),
  'body1': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__body1},
        --fmc-type-leading: #{$fds-line-height__body1},
        --fmc-type-tracking: #{fds-rem(1px)},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(18px)},
        --fmc-type-leading: 1.33,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'body2': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__body2},
        --fmc-type-leading: #{$fds-line-height__body2},
        --fmc-type-tracking: #{fds-rem(1px)},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(16px)},
        --fmc-type-leading: 1.25,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'body3': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__body3},
        --fmc-type-leading: #{$fds-line-height__body3},
        --fmc-type-tracking: #{$fds-letter-spacing__body3},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(13px)},
        --fmc-type-leading: 1.3,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'content1': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__content1},
        --fmc-type-leading: #{$fds-line-height__content1},
        --fmc-type-tracking: #{$fds-letter-spacing__content1},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(16px)},
        --fmc-type-leading: 1.25,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'content2': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__content2},
        --fmc-type-leading: #{$fds-line-height__content2},
        --fmc-type-tracking: #{$fds-letter-spacing__content2},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(13px)},
        --fmc-type-leading: 1,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'content3': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{$fds-font-size__content3},
        --fmc-type-leading: #{$fds-line-height__content3},
        --fmc-type-tracking: #{$fds-letter-spacing__content3},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(11px)},
        --fmc-type-leading: 1.18,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  ),
  'section': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-stretch: condensed,
        --fmc-type-size: #{fds-rem(24px)},
        --fmc-type-leading: 1.15,
        --fmc-type-tracking: initial,
        --fmc-type-transform: uppercase,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        font-stretch: var(--fmc-type-stretch),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(36px)},
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(48px)},
        --fmc-type-leading: 1.3,
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-stretch: initial,
        --fmc-type-size: #{fds-rem(19px)},
        --fmc-type-leading: 1.3,
        --fmc-type-tracking: #{fds-rem(2px)},
        --fmc-type-transform: uppercase
      ),
      'fds-sm': (
        --fmc-type-size: #{fds-rem(25px)},
      ),
      'fds-md': (
        --fmc-type-size: #{fds-rem(32px)},
      )
    )
  ),
  'legal': (
    'ford': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(11px)},
        --fmc-type-leading: 1.8,
        --fmc-type-tracking: #{fds-rem(1px)},
        --fmc-type-transform: initial,
        font-family: var(--fmc-type-family),
        font-size: var(--fmc-type-size),
        line-height: var(--fmc-type-leading),
        letter-spacing: var(--fmc-type-tracking),
        text-transform: var(--fmc-type-transform)
      )
    ),
    'lincoln': (
      'base': (
        --fmc-type-family: inherit,
        --fmc-type-size: #{fds-rem(11px)},
        --fmc-type-leading: 1.2,
        --fmc-type-tracking: initial,
        --fmc-type-transform: initial,
      )
    )
  )
) !default;

@mixin om-fds-set-type-attributes-important($breakpoints) {
  @each $breakpoint, $options in $breakpoints {
    @if $breakpoint == 'base' {
      @each $attr, $value in $options {
        #{$attr}: $value !important;
      }
    } @else {
      @include media('>#{$breakpoint}') {
        @each $attr, $value in $options {
          #{$attr}: $value !important;
        }
      }
    }
  }
}


@mixin om-fds-set-type-sets-important($parent, $modifier, $type-set) {
  @each $label, $brands in $type-set {
    #{$parent}#{$modifier}--#{$label} {
      @each $brand, $breakpoints in $brands {
        @if $brand == 'ford' {
          @include om-fds-set-type-attributes-important($breakpoints);
        } @else {
          @include fds-set-brand($brand) {
            @include om-fds-set-type-attributes-important($breakpoints);
          }
        }
      }
    }
  }
}

  
  // --------------------------------
  // Type Set Utility Classes
  // --------------------------------
  
  // creates classes from type-set
  @include om-fds-set-type-sets-important('.om-fmc-important','.fmc-type', $om-fds-type-sets);

.om-fmc-important {

  &.fmc-type--heading1,
  &.fmc-type--heading2,
  &.fmc-type--heading3,
  &.fmc-type--heading4,
  &.fmc-type--heading5,
  &.fmc-type--heading6,
  &.fmc-type--subtitle1,
  &.fmc-type--subtitle2,
  &.fmc-type--body1,
  &.fmc-type--body2,
  &.fmc-type--content2 {
    & a,
    & a:link,
    & a:active {
      text-decoration: underline !important;
  
      @include fds-set-brand(lincoln) {
        color: var(--fds-color--primary) !important;
        text-decoration-color: var(--fds-color--secondary) !important;
        transition: text-decoration-color 300ms !important;
      }
    }
  
    & a:hover {
      @include fds-set-brand(lincoln) {
        text-decoration-color: var(--fds-color--primary) !important;
      }
    }
  }
  
  // --------------------------------
  // Style
  // --------------------------------
  
  &.fds-style--italic {
    font-style: italic !important;
  }
  
  &.fds-style--normal {
    font-style: normal !important;
  }
  
  &.fds-transform--uppercase {
    text-transform: uppercase !important;
  }
  
  &.fds-transform--lowercase {
    text-transform: lowercase !important;
  }
  
  &.fds-transform--capitalize {
    text-transform: capitalize !important;
  }
  
  &.fds-transform--none {
    text-transform: none !important;
  }
  
  &.fds-decoration--underline {
    text-decoration: underline !important;
  }
  
  &.fds-decoration--overline {
    text-decoration: overline !important;
  }
  
  &.fds-decoration--none {
    text-decoration: none !important;
  }
  
  &.fds-decoration--strike {
    text-decoration: line-through !important;
  }
  
  // --------------------------------
  // Whitespace & Wrapping
  // --------------------------------
  
  &.fds-wrap--normal {
    white-space: normal !important;
  }
  
  &.fds-wrap--nowrap {
    white-space: nowrap !important;
  }
  
  &.fds-wrap--break-word {
    word-wrap: break-word !important;
  }
  
  &.fds-wrap--break-normal {
    word-wrap: normal !important;
  }
  
  &.fds-wrap--truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  
  // // --------------------------------
  // // Font weight
  // // --------------------------------
  
  // &.fds-weight--light {
  //   font-weight: var(--fds-type__weight-light) !important;
  // }
  
  // &.fds-weight--normal {
  //   font-weight: var(--fds-type__weight-normal) !important;
  // }
  
  // &.fds-weight--medium {
  //   font-weight: var(--fds-type__weight-medium) !important;
  // }
  
  // &.fds-weight--bold {
  //   font-weight: var(--fds-type__weight-bold) !important;
  // }
  
  // --------------------------------
  // Font stretch
  // --------------------------------
  &.fds-stretch--condensed {
    font-stretch: condensed !important;
  }
  
  &.fds-stretch--normal {
    font-stretch: normal !important;
  }
  
  // --------------------------------
  // Lists
  // --------------------------------
  
  &.fds-list-reset {
    padding: 0 !important;
    list-style-type: none !important;
  }

  
  // --------------------------------
  // Headings
  // --------------------------------
  
  // type set aliases to map heading levels to display nomenclature
  &.fmc-type--h1 {
    @extend .fmc-type--heading1;
  }
  
  &.fmc-type--h2 {
    @extend .fmc-type--heading2;
  }
  
  &.fmc-type--h3 {
    @extend .fmc-type--heading3;
  }
  
  &.fmc-type--h4 {
    @extend .fmc-type--heading4;
  }
  
  &.fmc-type--h5 {
    @extend .fmc-type--heading5;
  }
  
  &.fmc-type--h6 {
    @extend .fmc-type--heading6;
  }
}
