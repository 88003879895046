// ================================
// Tools: Extends
// ================================

// Visually hide elements while at the same time make them accessible on screen readers
%fds-visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

// Add ellipsis to long text
%fds-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Font-smoothing for OSX
%fds-font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

// Remove font-smoothing
%fds-no-font-smoothing {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
    text-shadow: none;
}

// Sets the base level icon styles
%fds-icons {
    --icon-font: 'FordIcons';
    --icon-size: #{fds-rem(16px)};
    font-family: var(--icon-font);
    font-size: var(--icon-size);
    line-height: 1;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    letter-spacing: normal;
    font-feature-settings: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include fds-set-brand(lincoln) {
        --icon-font: 'LincolnIcons';
    }
}
