.fds-demo__carousel {
    max-width: fds-rem(1920px);
    background-color: var(--fds-color--white);
    margin: 0 auto;
}

.fds-guidelines__page-content {
    & .fds-carousel-wrapper {
        margin-left: fds-rem(-30px);
        margin-right: fds-rem(-30px);
    }
}

.fds-carousel-wrapper {
    padding-bottom: fds-rem(50px);
    overflow: hidden;
    margin-left: calc(-1 * var(--fds-layout-grid-padding-sm));
    margin-right: calc(-1 * var(--fds-layout-grid-padding-sm));
    @include media('>=fds-md') {
        margin-left: calc(-1 * var(--fds-layout-grid-padding-md));
        margin-right: calc(-1 * var(--fds-layout-grid-padding-md));
    }

    & .fds-carousel {
        display: grid;
        grid-auto-flow: column;
        position: relative;
        transition: left 0.5s;
        align-items: stretch;

        // 0.6rem subtraction to allow more of the card to peak at smaller size
        gap: calc(var(--fds-layout-grid-gutter-sm) - #{fds-rem(6px)});
        padding-left: var(--fds-layout-grid-padding-sm);
        padding-right: var(--fds-layout-grid-padding-sm);
        @include media('>=fds-md') {
            gap: var(--fds-layout-grid-gutter-md);
            padding-left: var(--fds-layout-grid-padding-md);
            padding-right: var(--fds-layout-grid-padding-md);
        }
    }

    .fds-carousel-indicator {
        margin-top: fds-rem(40px);
        display: flex;
        justify-content: center;

        & .fds-carousel-indicator__button-wrapper {
            margin: fds-rem(0 10px);
        }

        & .fds-carousel-indicator__pagination-slash {
            margin: 0 fds-rem(12px);
        }
    }
}
