// ================================
// Utilities: Background
// ================================

$fds-bgPosition: (
    bottom: bottom,
    center: center,
    left: left,
    left-bottom: left bottom,
    left-top: left top,
    right: right,
    right-bottom: right bottom,
    right-top: right top,
    top: top,
) !default;

$fds-bgRepeat: (
    repeat: repeat,
    no-repeat: no-repeat,
    repeat-x: repeat-x,
    repeat-y: repeat-y,
) !default;

$fds-bgSize: (
    auto: auto,
    cover: cover,
    contain: contain,
    contain-x: 100% auto,
    contain-y: auto 100%,
) !default;

@include fds-set-selectors($fds-bgPosition, 'background-position', 'fds-color__bg--');
@include fds-set-selectors($fds-bgRepeat, 'background-repeat', 'fds-color__bg--');
@include fds-set-selectors($fds-bgSize, 'background-size', 'fds-color__bg--');
