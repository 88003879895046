// ================================
// Base: Forms
// ================================

// No outline for non-keyboard-inputs elements
button:focus,
input:focus,
select:focus,
div:focus {
    outline: none;
}

button:disabled,
input:disabled,
select:disabled,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder {
    opacity: 1;
}

button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    font-weight: inherit;
    color: inherit;
}
