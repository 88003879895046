.fds-password-field {
    & .fds-password-field__mask-button {
        opacity: 0;
        margin-right: fds-rem(12px);
        transition: opacity 0.3s ease-in-out;

        &.fds-password-field__mask-button--focused {
            outline: 1px solid var(--fds-color--primary);
            outline-offset: fds-rem(10px);
        }
    }

    &.fds-text-field__input--invalid,
    &.fds-text-field__input--active,
    &.fds-text-field__input--dirty {
        & .fds-password-field__password-reqs {
            display: block;
        }
    }

    &.fds-text-field__input--dirty,
    & .fds-text-field__input--dirty {
        & .fds-password-field__mask-button {
            opacity: 1;
        }
    }
}

.fds-password-field__strength {
    opacity: 0;
    display: none;
    align-items: center;
    justify-content: flex-end;
    transition: opacity 0.3s ease-in-out;
}

.fds-password-field__strength-inner {
    display: flex;
}

.fds-text-field__input--dirty {
    &.fds-text-field__input--active[class*='fds-password-field__strength'],
    & .fds-text-field__input--active[class*='fds-password-field__strength'] {
        & .fds-text-field__message {
            color: var(--fds-color--gray3);
        }

        & .fds-text-field__status {
            display: flex;
            height: inherit;
            padding-top: fds-rem(7px);
        }

        & .fds-password-field__strength,
        & .fds-text-field__message {
            opacity: 1;
            display: flex;
        }
    }
}

.fds-password-field__strength-text {
    display: block;
}

.fds-password-field__strength--weak.fds-text-field__input--active,
.fds-password-field__strength--weak.fds-text-field__input--dirty,
.fds-password-field__strength--weak .fds-text-field__input--active,
.fds-password-field__strength--weak .fds-text-field__input--dirty {
    & .fds-password-field__strength-tick:first-child,
    & .fds-text-field__input-wrapper {
        border-color: var(--fds-color--error1);
    }
}

.fds-password-field__strength--better.fds-text-field__input--active,
.fds-password-field__strength--better.fds-text-field__input--dirty,
.fds-password-field__strength--better .fds-text-field__input--active,
.fds-password-field__strength--better .fds-text-field__input--dirty {
    & .fds-password-field__strength-tick:first-child,
    & .fds-password-field__strength-tick:nth-child(2),
    & .fds-text-field__input-wrapper {
        border-color: var(--fds-color--caution2);
    }
}

.fds-password-field__strength--strong.fds-text-field__input--active,
.fds-password-field__strength--strong.fds-text-field__input--dirty,
.fds-password-field__strength--strong .fds-text-field__input--active,
.fds-password-field__strength--strong .fds-text-field__input--dirty {
    & .fds-password-field__strength-tick:first-child,
    & .fds-password-field__strength-tick:nth-child(2),
    & .fds-password-field__strength-tick:last-child,
    & .fds-text-field__input-wrapper {
        border-color: var(--fds-color--success1);
    }
}

.fds-password-field__strength-tick {
    width: fds-rem(20px);
    height: fds-rem(2px);
    border: solid fds-rem(1px) var(--fds-color--gray3);
    margin-left: fds-rem(5px);
    border-radius: fds-rem(2px);
}

.fds-password-field__password-reqs {
    display: none;
    padding-top: fds-rem(20px);
}

.fds-password-field__password-reqs-message,
.fds-password-field__password-reqs-list-item {
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
    color: var(--fds-color--gray3);
}

.fds-password-field__password-reqs-list {
    margin-top: fds-rem(20px);
    list-style: none;
    position: relative;
    padding-left: 0;
}

.fds-password-field__password-reqs-list-item {
    display: flex;
    align-items: center;
    padding-bottom: fds-rem(10px);

    & > span {
        padding-left: fds-rem(27px);
    }

    &::before {
        position: absolute;
        font-family: 'FordIcons' !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: fds-rem(16px);
        letter-spacing: normal;
        font-feature-settings: normal;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        padding-right: fds-rem(11px);
        content: '';
    }
}

.fds-password-field__password-reqs-list-item--success {
    color: var(--fds-color--primary);

    &::before {
        content: $fds-font--ford-icons__success;
        color: var(--fds-color--success1);
    }
}
