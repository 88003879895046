.fds-billboard-carousel {
    & .fds-carousel-indicator {
        & .fds-carousel-indicator-wrapper {
            background-color: var(--fds-color--tertiary-70);
            border-radius: fds-rem(30px);
            padding: fds-rem(18px 20px);
            position: absolute;
            bottom: fds-rem(20px);

            @include media('>=fds-sm') {
                bottom: fds-rem(30px);
            }
        }

        & .fds-carousel-indicator__button--active {
            & .fds-carousel-indicator__pancake {
                background-color: var(--fds-color--white);
            }
        }

        & .fds-carousel-indicator__button--disabled {
            & .fds-carousel-indicator__pancake {
                background-color: var(--fds-color--disabled1);
            }

            & .fds-carousel-indicator__directional-button__text {
                color: var(--fds-color--disabled1);
            }
        }

        & .fds-carousel-indicator__directional-button,
        & .fds-carousel-indicator__button--disabled {
            width: auto;
            height: auto;

            &::before {
                background-color: var(--fds-color--white-0);
            }

            &:hover::before,
            &:active::before,
            &.fds-carousel-indicator__button--active::before {
                background-color: var(--fds-color--white-0);
                transform: none;
                box-shadow: none;
            }
        }

        & .fds-carousel-indicator__pagination-text {
            color: var(--fds-color--white);
        }
    }

    & .fds-using-keyboard {
        & .fds-carousel-indicator__directional-button:focus,
        & .fds-carousel-indicator__pancake-button:focus,
        & .fds-carousel-indicator__pagination-text:focus {
            outline-color: var(--fds-color--white);
        }
    }
}
