$fds-dropdown__option-height: fds-rem(50px);
$fds-dropdown__option-max: 5;

.fds-dropdown {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-top: fds-rem(27px);
    overflow: visible;
}

.fds-dropdown__valid {
    & .fds-dropdown__button,
    & .fds-dropdown__select {
        border-color: var(--fds-color--success1);
    }

    & .fds-dropdown__status {
        display: flex;
    }

    & .fds-dropdown__message {
        opacity: 0.01;
        height: 0;
    }

    & .fds-dropdown__label-icon {
        display: block;
    }
}

.fds-dropdown__invalid {
    & .fds-dropdown__button,
    & .fds-dropdown__select {
        border-color: var(--fds-color--error1);
    }

    & .fds-dropdown__status {
        display: flex;
    }

    & .fds-dropdown__message {
        opacity: 1;
        color: var(--fds-color--error1);
    }

    & .fds-dropdown__label-icon {
        display: block;
    }
}

.fds-dropdown__focus-outline {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
}

.fds-dropdown--touch {
    & .fds-dropdown__select {
        display: block;
        opacity: 0;
    }

    &.fds-dropdown--dirty {
        & .fds-dropdown__select {
            opacity: 1;
        }

        & .fds-dropdown__button {
            display: none;
        }
    }
}

.fds-dropdown__icon {
    position: absolute;
    top: fds-rem(15px);
    right: fds-rem(15px);
    transition: transform 0.3s ease-in-out;

    &::after {
        font-family: 'FordIcons';
        font-size: fds-rem(16px);
        line-height: fds-rem(16px);
        color: var(--fds-color--primary);
        content: $fds-font--ford-icons__chevron-down;
    }
}

.fds-dropdown__placeholder {
    position: absolute;
    left: fds-rem(15px);
    top: fds-rem(18px);
    @include fds-get-component-typeset('dropdown-option');
}

.fds-dropdown--dirty {
    & .fds-dropdown__button,
    & .fds-dropdown__select {
        color: var(--fds-color--primary);
    }

    & .fds-dropdown__placeholder {
        display: none;
    }
}

.fds-dropdown__label-wrapper {
    display: flex;
    align-items: center;
}

.fds-dropdown--active,
.fds-dropdown--dirty {
    & .fds-dropdown__label {
        position: relative;
        opacity: 1;
        margin-right: fds-rem(5px);
    }
}

.fds-dropdown--active,
.fds-dropdown--hover {
    & .fds-dropdown__button-wrapper {
        box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer2;
        background-color: var(--fds-color--white);
        color: var(--fds-color--primary);
    }
}

.fds-dropdown--focused {
    & .fds-dropdown__focus-outline {
        outline: 1px solid var(--fds-color--primary);
        outline-offset: fds-rem(10px);
    }
}

.fds-dropdown__list--show {
    & .fds-dropdown__icon {
        transform: rotate(-180deg);
    }

    &:not(.fds-dropdown--touch) {
        & .fds-dropdown__list-wrapper {
            opacity: 1;
            display: block;
        }

        & .fds-dropdown__button {
            border-radius: fds-rem(3px 3px 0 0);
        }
    }
}

.fds-dropdown__instructions {
    display: none;
}

.fds-dropdown__eyebrow {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fds-rem(16px);
    margin-bottom: fds-rem(13px);
}

.fds-dropdown__label {
    @include fds-get-component-typeset('dropdown-label');

    position: absolute;
    white-space: nowrap;
    opacity: 0;
    color: var(--fds-color--gray3);
    transition: opacity 0.3s ease-in-out;
}

.fds-dropdown__label-icon {
    display: none;
}

.fds-dropdown__button-wrapper {
    position: relative;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: fds-rem(50px);
    width: 100%;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.fds-dropdown__select-option--hide {
    display: none;
}

.fds-dropdown__button,
.fds-dropdown__select {
    @include fds-get-component-typeset('dropdown-option');

    position: absolute;
    top: 0;
    -webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */
    display: none;
    background: none;
    color: var(--fds-color--gray3);
    resize: none;
    width: 100%;
    height: fds-rem(50px);
    padding: fds-rem(15px);
    border: solid 1px var(--fds-color--gray2);
    border-radius: fds-rem(3px);
}

.fds-dropdown__button {
    display: flex;
    align-items: center;
}

.fds-dropdown__select {
    height: 100%;
    z-index: 3;
}

.fds-dropdown__list-wrapper {
    display: none;
    width: 100%;
    top: fds-rem(77px);
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 3;
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer2;
    transition: opacity 0.3s ease-in-out;
}

.fds-dropdown__list {
    width: 100%;
    max-height: calc(#{$fds-dropdown__option-max} * #{$fds-dropdown__option-height});
    overflow-y: auto;
    border-radius: 0 0 fds-rem(3px) fds-rem(3px);
    background-color: var(--fds-color--white);
    color: var(--fds-color--primary);
    border: solid 1px var(--fds-color--gray2);
    border-top: none;
    list-style: none;
    padding: 0;
    outline: none;
}

.fds-dropdown__list-option {
    @include fds-get-component-typeset('dropdown-option');

    color: var(--fds-color--primary);
    line-height: $fds-dropdown__option-height;
    padding: fds-rem(0 16px);
    cursor: pointer;
}

.fds-dropdown__list-option--focused,
.fds-dropdown__list-option:focus {
    background-color: var(--fds-color--gray2);
    color: var(--fds-color--white);
    outline: none;
}

.fds-dropdown--disabled {
    & .fds-dropdown__button-wrapper {
        cursor: auto;

        & .fds-dropdown__placeholder,
        & .fds-dropdown__icon {
            color: var(--fds-color--white);
            z-index: 1;

            &::after {
                color: var(--fds-color--white);
            }
        }

        & .fds-dropdown__button {
            background-color: var(--fds-color--disabled3);
            color: var(--fds-color--white);
            cursor: not-allowed;
        }
    }
}

.fds-dropdown__message {
    opacity: 0;
    width: 100%;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
}

.fds-dropdown__status {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: fds-rem(7px);
}
