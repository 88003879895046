.om-badge-notification {
  position: relative;
  z-index: 0;
  .om-badge-notification__amount {
    @extend .fmc-type--content2;
    color: $fds-color--white;
    border: 1px solid $fds-color--white;
    position: absolute;
    right: -1rem;
    top: -.25rem;
    z-index: 1;
    background-color: $fds-color--error2;
    padding: .25rem;
    border-radius: 2.25rem;
    width: 2.25rem;
    height: 2.25rem;
    text-align: center;
  }
}