.fds-specialty-button {
    display: inline-flex;
    position: relative;

    & input {
        background: none;
        border: none;
    }

    &.fds-color__bg--light {
        // Default
        & .fds-specialty-button__button-wrapper {
            &::before {
                background-color: var(--fds-color--primary);
            }

            & .fds-specialty-button__button {
                color: var(--fds-color--white);
            }
        }

        // Hover, Active
        &.fds-specialty-button--hover,
        &.fds-specialty-button--active {
            & .fds-specialty-button__button-wrapper::before {
                background-color: var(--fds-color--secondary);
            }
        }

        // Focus
        &.fds-specialty-button--focused {
            & .fds-specialty-button__button-wrapper::before {
                outline-color: var(--fds-color--primary);
            }
        }
    }

    &.fds-color__bg--dark {
        // Default
        & .fds-specialty-button__button-wrapper {
            &::before {
                background-color: var(--fds-color--white);
            }

            & .fds-specialty-button__button {
                color: var(--fds-color--primary);
            }
        }

        // Hover, Active
        &.fds-specialty-button--hover,
        &.fds-specialty-button--active {
            & .fds-specialty-button__button-wrapper::before {
                background-color: var(--fds-color--gray1);
            }
        }

        // Focus
        &.fds-specialty-button--focused {
            & .fds-specialty-button__button-wrapper::before {
                outline-color: var(--fds-color--white);
            }
        }
    }

    // Hover
    &.fds-specialty-button--hover:not(.fds-specialty-button--touch) {
        & .fds-specialty-button__button-wrapper::before {
            transform: scale(1.07);
            box-shadow: $fds-elevation__box-shadow--layer3;
        }
    }

    // Active
    &.fds-specialty-button--active {
        & .fds-specialty-button__button-wrapper::before {
            transform: scale(1);
            box-shadow: $fds-elevation__box-shadow--layer1;
        }
    }

    // Focus
    &.fds-specialty-button--focused {
        & .fds-specialty-button__button-wrapper::before {
            outline-width: 1px;
            outline-style: solid;
            outline-offset: fds-rem(20px);
        }
    }
}

.fds-specialty-button__button-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    position: relative;

    // remove flash on iOS mobile as accessibility indicators are customized in this CSS
    -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

    // prevent button elements from shifting in IE11
    top: 0;
    left: 0;

    &::before {
        content: '';
        border-radius: fds-rem(3px);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: $fds-elevation__box-shadow--layer2;
        will-change: transform, box-shadow;

        // Transition speeds coincide with the JavaScript, so if these speeds change,
        // the JavaScript must be updated accordingly
        transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
            box-shadow 0.3s ease-in-out;
    }

    & .fds-specialty-button__button {
        @include fds-get-component-typeset('specialty-button');

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 400;
        text-align: center;
        padding: fds-rem(10px);
        height: fds-rem(150px);
        min-width: fds-rem(150px);
        max-width: fds-rem(186px);
        word-break: break-all;
        pointer-events: none; // Fix for IE11 :active not firing on link click
    }
}
