[class^='fds-icon'],
[class*='fds-icon'] {
    @extend %fds-icons;
}

.fds-icon--24 {
    font-size: fds-rem(24px);
}

.fds-icon--32 {
    font-size: fds-rem(32px);
}

.fds-icon--48 {
    font-size: fds-rem(48px);
}

.fds-icon--64 {
    font-size: fds-rem(64px);
}

.fds-icon--offset-left {
    --offset: 1.6rem;

    @include fds-set-brand(lincoln) {
        --offset: 0.5rem;
    }

    margin-right: var(--offset);
}

.fds-icon--offset-right {
    --offset: 1.6rem;

    @include fds-set-brand(lincoln) {
        --offset: 0.5rem;
    }

    margin-left: var(--offset);
}

.fds-icon--offset-left-xs {
    margin-right: 0.4rem;
}

.fds-icon--offset-right-xs {
    margin-left: 0.4rem;
}

.fds-icon--offset-left-sm {
    margin-right: 0.8rem;
}

.fds-icon--offset-right-sm {
    margin-left: 0.8rem;
}

.fds-icon--offset-left-lg {
    margin-right: 2.4rem;
}

.fds-icon--offset-right-lg {
    margin-left: 2.4rem;
}

.fds-font--color__primary {
    color: var(--fds-color--primary);
}

.fds-font--color__error {
    color: var(--fds-color--error1);
}

.fds-font--color__success {
    color: var(--fds-color--success1);
}

.fds-font--color__gray {
    color: var(--fds-color--gray3);
}

.fds-font--ford-icons__play-arrow {
    &::before {
        content: $fds-font--ford-icons__play-arrow;
    }
}

.fds-font--ford-icons__chevron-filled-right-path1,
.fds-font--ford-icons__chevron-filled-right {
    &::before {
        content: $fds-font--ford-icons__chevron-filled-right;
        display: inline-block;
    }
}

.fds-font--ford-icons__chevron-filled-left-path1,
.fds-font--ford-icons__chevron-filled-left {
    &::before {
        display: inline-block;
        content: $fds-font--ford-icons__chevron-filled-left;
    }
}

.fds-font--ford-icons__error {
    &::before {
        content: $fds-font--ford-icons__error;
    }
}

.fds-font--ford-icons__clear {
    &::before {
        content: $fds-font--ford-icons__clear;
    }
}

.fds-font--ford-icons__success {
    &::before {
        content: $fds-font--ford-icons__success;
    }
}

.fds-font--ford-icons__unmask {
    &::before {
        content: $fds-font--ford-icons__unmask;
    }
}

.fds-font--ford-icons__mask {
    &::before {
        content: $fds-font--ford-icons__mask;
    }
}

.fds-font--ford-icons__tick {
    &::before {
        content: $fds-font--ford-icons__tick;
    }
}

.fds-font--ford-icons__chevron-left {
    &::before {
        content: $fds-font--ford-icons__chevron-left;
    }
}

.fds-font--ford-icons__chevron-right {
    &::before {
        content: $fds-font--ford-icons__chevron-right;
    }
}

.fds-font--ford-icons__chevron-up {
    &::before {
        content: $fds-font--ford-icons__chevron-up;
    }
}

.fds-font--ford-icons__chevron-down {
    &::before {
        content: $fds-font--ford-icons__chevron-down;
    }
}

.fds-font--ford-icons__edit {
    &::before {
        content: $fds-font--ford-icons__edit;
    }
}

.fds-font--ford-icons__info-filled {
    &::before {
        content: $fds-font--ford-icons__info-filled;
    }
}

.fds-font--ford-icons__warning-filled {
    &::before {
        content: $fds-font--ford-icons__warning-filled;
    }
}

.fds-font--ford-icons__check-filled {
    &::before {
        content: $fds-font--ford-icons__check-filled;
    }
}

.fds-font--ford-icons__dark-car-rear {
    &::before {
        content: $fds-font--ford-icons__dark-car-rear;
    }
}

.fds-font--ford-icons__dark-car-side {
    &::before {
        content: $fds-font--ford-icons__dark-car-side;
    }
}

.fds-font--ford-icons__dark-seat {
    &::before {
        content: $fds-font--ford-icons__dark-seat;
    }
}

.fds-font--ford-icons__search {
    &::before {
        content: $fds-font--ford-icons__search;
    }
}

.fds-font--ford-icons__chat {
    &::before {
        content: $fds-font--ford-icons__chat;
    }
}

.fds-font--ford-icons__info-outlined {
    &::before {
        content: $fds-font--ford-icons__info-outlined;
    }
}

.fds-font--ford-icons__long-arrow-back {
    &::before {
        content: $fds-font--ford-icons__long-arrow-back;
    }
}

.fds-font--ford-icons__long-arrow-forward {
    &::before {
        content: $fds-font--ford-icons__long-arrow-forward;
    }
}

.fds-font--ford-icons__plus {
    &::before {
        content: $fds-font--ford-icons__plus;
    }
}

.fds-font--ford-icons__minus {
    &::before {
        content: $fds-font--ford-icons__minus;
    }
}

.fds-font--ford-icons__ellipsis {
    &::before {
        content: $fds-font--ford-icons__ellipsis;
    }
}
