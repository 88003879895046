.fds-accordion {
    position: relative;
    width: 100%;
}

.fds-accordion__button {
    @include fds-get-component-typeset('accordion');

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fds-rem(50px);
    padding: fds-rem(0 15px);
    cursor: pointer;
    transition: background-color 0.3s cubic-bezier(0.22, 0.61, 0.35, 1);

    & .fds-icon {
        transition: transform 0.3s ease-in-out;
    }

    & .fds-icon::after {
        margin: 3px;
        content: $fds-font--ford-icons__chevron-down;
    }
}

.fds-accordion__panel {
    &:not(:last-child) {
        margin-bottom: fds-rem(2px);
    }

    &.fds-accordion--focused {
        & .fds-accordion__button {
            outline-offset: fds-rem(-10px);
            outline-width: 1px;
            outline-style: solid;
        }
    }

    &.fds-accordion--expanded {
        & .fds-accordion__body {
            height: auto;
        }

        & .fds-accordion__button {
            & .fds-icon {
                transform: rotate(-180deg);
            }
        }
    }
}

.fds-accordion__content {
    padding: fds-rem(20px 15px);
}

.fds-accordion__body {
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.22, 0.61, 0.35, 1);
    text-overflow: clip;
    height: 0;
}

.fds-color__bg--light {
    & .fds-accordion__button {
        background-color: var(--fds-color--primary);

        & * {
            color: var(--fds-color--white);
        }
    }

    & .fds-accordion__body {
        background-color: var(--fds-color--white);
    }

    & .fds-accordion--hover,
    & .fds-accordion--focused,
    & .fds-accordion--active,
    & .fds-accordion--expanded {
        & .fds-accordion__button {
            background-color: var(--fds-color--secondary);
        }
    }

    & .fds-accordion--focused {
        & .fds-accordion__button {
            outline-color: var(--fds-color--white);
        }
    }

    &.fds-accordion--light {
        & .fds-accordion__panel {
            margin-bottom: 0;
            border-top: 1px solid var(--fds-color--gray3);
        }

        & .fds-accordion__panel:last-child {
            border-bottom: 1px solid var(--fds-color--gray3);
        }

        & .fds-accordion__button {
            background-color: var(--fds-color--white);
            outline-color: var(--fds-color--primary);

            & * {
                color: var(--fds-color--primary);
            }
        }

        & .fds-accordion__body {
            background-color: transparent;
        }
    }
}

.fds-color__bg--dark {
    & .fds-accordion__button {
        background-color: var(--fds-color--white);

        & .fds-icon {
            color: var(--fds-color--primary);
        }

        & .fds-accordion__button-text {
            color: var(--fds-color--gray3);
        }
    }

    & .fds-accordion__body {
        background-color: var(--fds-color--white);
    }

    & .fds-accordion--hover,
    & .fds-accordion--focused,
    & .fds-accordion--active,
    & .fds-accordion--expanded {
        & .fds-accordion__button {
            background-color: var(--fds-color--gray1);
        }
    }

    & .fds-accordion--focused {
        & .fds-accordion__button {
            outline-color: var(--fds-color--primary);
        }
    }
}
