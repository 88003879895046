// ================================
// Tools: Get typeset
// ================================

/**
 * Maps over breakpoints and itterates over options to create list of attributes for each type-set.
 * @param {map} $breakpoints - SCSS map object that contains a list of breakpoints to map over.
 */
@mixin fds-set-type-attributes($breakpoints) {
    @each $breakpoint, $options in $breakpoints {
        @if $breakpoint == 'base' {
            @each $attr, $value in $options {
                #{$attr}: $value;
            }
        } @else {
            @include media('>#{$breakpoint}') {
                @each $attr, $value in $options {
                    #{$attr}: $value;
                }
            }
        }
    }
}

/**
 * Maps over type-sets to generate utility classes or placeholders to extend types by.
 * @param {string} $modifier - The name that is prefixed when mapping over.
 * @param {map} $type-set - SCSS map object that contains list of type-sets to map over.
 */
@mixin fds-set-type-sets($modifier, $type-set) {
    @each $label, $brands in $type-set {
        #{$modifier}--#{$label} {
            @each $brand, $breakpoints in $brands {
                @if $brand == 'ford' {
                    @include fds-set-type-attributes($breakpoints);
                } @else {
                    @include fds-set-brand($brand) {
                        @include fds-set-type-attributes($breakpoints);
                    }
                }
            }
        }
    }
}

@mixin fds-get-component-typeset($type-set) {
    @if map-has-key($fds-component-type-sets, $type-set) {
        $type-set-name: map-get($fds-component-type-sets, $type-set);

        @each $breakpoint, $options in $type-set-name {
            $fluidParams: ();
            @if $breakpoint == 'fluid' {
                @each $param in $options {
                    $fluidParams: append($fluidParams, $param, comma);
                }
                @include fds-get-fluid-type($fluidParams...);
            } @else if $breakpoint == 'base' {
                @each $attr, $value in $options {
                    #{$attr}: $value;
                }
            } @else {
                @include media('>=#{$breakpoint}') {
                    @each $attr, $value in $options {
                        #{$attr}: $value;
                    }
                }
            }
        }
    } @else {
        @warn "Couldn't find a typeset named `#{$type-set}`.";
    }
}
