[class*=fds-icon] {
  position: relative;
}

.fds-font--ford {
  &-icons {
    padding-right: 1.6rem;
    &-warning-outline::before,
    &-business-plan::before,
    &-enrollment::before,
    &-edit-profile::before,
    &-notification::before,
    &-open-white::before,
    &-open::before,
    &-download::before,
    &-download-primary::before,
    &-circle-clock-caution::before,
    &-circle-times-error::before,
    &-exception::before,
    &-settings::before,
    &-disabled::before,
    &-location::before,
    &-filter::before,
    &-refresh::before,
    &-delete::before,
    &-email::before,
    &-circle-check-success::before {
      content: '';
      height: 1.6rem;
      width: 1.6rem;
      position: absolute;
      z-index: 1;
      top: calc(50% - 0.6rem);
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.om-fds-icon--16 {
      padding-right: 1.6rem;
    }
    &.om-fds-icon--24 {
      padding-right: 2.4rem;
    }
    &.om-fds-icon--32 {
      padding-right: 3.2rem;
    }
    &.om-fds-icon--48 {
      padding-right: 4.8rem;
    }

    &-warning-outline.om-fds-icon--16::before,
    &-business-plan.om-fds-icon--16::before,
    &-enrollment.om-fds-icon--16::before,
    &-edit-profile.om-fds-icon--16::before,
    &-notification.om-fds-icon--16::before,
    &-open-white.om-fds-icon--16::before,
    &-open.om-fds-icon--16::before,
    &-download.om-fds-icon--16::before,
    &-download-primary.om-fds-icon--16::before,
    &-circle-clock-caution.om-fds-icon--16::before,
    &-circle-times-error.om-fds-icon--16::before,
    &-exception.om-fds-icon--16::before,
    &-settings.om-fds-icon--16::before,
    &-disabled.om-fds-icon--16::before,
    &-location.om-fds-icon--16::before,
    &-filter.om-fds-icon--16::before,
    &-refresh.om-fds-icon--16::before,
    &-delete.om-fds-icon--16::before,
    &-email.om-fds-icon--16::before,
    &-circle-check-success.om-fds-icon--16::before {
      height: 1.6rem;
      width: 1.6rem;
      top: calc(50% - 0.6rem);
    }
    &-warning-outline.om-fds-icon--24::before,
    &-business-plan.om-fds-icon--24::before,
    &-enrollment.om-fds-icon--24::before,
    &-edit-profile.om-fds-icon--24::before,
    &-notification.om-fds-icon--24::before,
    &-open-white.om-fds-icon--24::before,
    &-open.om-fds-icon--24::before,
    &-download.om-fds-icon--24::before,
    &-download-primary.om-fds-icon--24::before,
    &-circle-clock-caution.om-fds-icon--24::before,
    &-circle-times-error.om-fds-icon--24::before,
    &-exception.om-fds-icon--24::before,
    &-settings.om-fds-icon--24::before,
    &-disabled.om-fds-icon--24::before,
    &-location.om-fds-icon--24::before,
    &-filter.om-fds-icon--24::before,
    &-refresh.om-fds-icon--24::before,
    &-delete.om-fds-icon--24::before,
    &-email.om-fds-icon--24::before,
    &-circle-check-success.om-fds-icon--24::before {
      height: 2.4rem;
      width: 2.4rem;
      top: calc(50% - 1.2rem);
    }
    &-warning-outline.om-fds-icon--36::before,
    &-business-plan.om-fds-icon--36::before,
    &-enrollment.om-fds-icon--36::before,
    &-edit-profile.om-fds-icon--36::before,
    &-notification.om-fds-icon--36::before,
    &-open-white.om-fds-icon--36::before,
    &-open.om-fds-icon--36::before,
    &-download.om-fds-icon--36::before,
    &-download-primary.om-fds-icon--36::before,
    &-circle-clock-caution.om-fds-icon--36::before,
    &-circle-times-error.om-fds-icon--36::before,
    &-exception.om-fds-icon--36::before,
    &-settings.om-fds-icon--36::before,
    &-disabled.om-fds-icon--36::before,
    &-location.om-fds-icon--36::before,
    &-filter.om-fds-icon--36::before,
    &-refresh.om-fds-icon--36::before,
    &-email.om-fds-icon--36::before,
    &-delete.om-fds-icon--36::before,
    &-circle-check-success.om-fds-icon--36::before {
      height: 3.2rem;
      width: 3.2rem;
      top: calc(50% - 1.6rem);
    }
    &-warning-outline.om-fds-icon--48::before,
    &-business-plan.om-fds-icon--48::before,
    &-enrollment.om-fds-icon--48::before,
    &-edit-profile.om-fds-icon--48::before,
    &-notification.om-fds-icon--48::before,
    &-open-white.om-fds-icon--48::before,
    &-open.om-fds-icon--48::before,
    &-download.om-fds-icon--48::before,
    &-download-primary.om-fds-icon--48::before,
    &-circle-clock-caution.om-fds-icon--48::before,
    &-circle-times-error.om-fds-icon--48::before,
    &-exception.om-fds-icon--48::before,
    &-settings.om-fds-icon--48::before,
    &-disabled.om-fds-icon--48::before,
    &-location.om-fds-icon--48::before,
    &-filter.om-fds-icon--48::before,
    &-refresh.om-fds-icon--48::before,
    &-email.om-fds-icon--48::before,
    &-delete.om-fds-icon--48::before,
    &-circle-check-success.om-fds-icon--48::before {
      height: 4.8rem;
      width: 4.8rem;
      top: calc(50% - 2.4rem);
    }

    &-warning-outline.om-fds-icon-top--16::before,
    &-business-plan.om-fds-icon-top--16::before,
    &-enrollment.om-fds-icon-top--16::before,
    &-edit-profile.om-fds-icon-top--16::before,
    &-notification.om-fds-icon-top--16::before,
    &-open-white.om-fds-icon-top--16::before,
    &-open.om-fds-icon-top--16::before,
    &-download.om-fds-icon-top--16::before,
    &-download-primary.om-fds-icon-top--16::before,
    &-circle-clock-caution.om-fds-icon-top--16::before,
    &-circle-times-error.om-fds-icon-top--16::before,
    &-exception.om-fds-icon-top--16::before,
    &-settings.om-fds-icon-top--16::before,
    &-disabled.om-fds-icon-top--16::before,
    &-location.om-fds-icon-top--16::before,
    &-filter.om-fds-icon-top--16::before,
    &-refresh.om-fds-icon-top--16::before,
    &-email.om-fds-icon-top--16::before,
    &-delete.om-fds-icon-top--16::before,
    &-circle-check-success.om-fds-icon-top--16::before {
      top: -1.6rem;
    }
    &-warning-outline {
      &:before {
        background-image: url('~/public/assets/images/icon-warning-outline.svg');
      }
    }
    &-business-plan {
      &:before {
        background-image: url('~/public/assets/images/icon-business-plan.svg');
      }
    }
    &-enrollment {
      &:before {
        background-image: url('~/public/assets/images/icon-enrollment.svg');
      }
    }
    &-edit-profile {
      &:before {
        background-image: url('~/public/assets/images/icon-edit-profile.svg');
      }
    }
    &-notification {
      &:before {
        background-image: url('~/public/assets/images/icon-notification.svg');
      }
    }
    &-open-white {
      &:before {
        background-image: url('~/public/assets/images/icon-open-white.svg');
      }
    }
    &-open {
      &:before {
        background-image: url('~/public/assets/images/icon-open.svg');
      }
    }
    &-download {
      &:before {
        background-image: url('~/public/assets/images/icon-download.svg');
      }
    }
    &-download-primary {
      &:before {
        background-image: url('~/public/assets/images/icon-download-primary.svg');
      }
    }
    &-circle-times-error {
      &:before {
        background-image: url('~/public/assets/images/icon-circle-times-error.svg');
      }
    }
    &-circle-check-success {
      &:before {
        background-image: url('~/public/assets/images/icon-circle-check-success.svg');
      }
    }
    &-circle-clock-caution {
      &:before {
        background-image: url('~/public/assets/images/icon-circle-clock-caution.svg');
      }
    }
    &-exception {
      &:before {
        background-image: url('~/public/assets/images/icon-exception.svg');
      }
    }
    &-settings {
      &:before {
        background-image: url('~/public/assets/images/icon-settings.svg');
      }
    }
    &-location {
      &:before {
        background-image: url('~/public/assets/images/icon-location.svg');
      }
    }
    &-filter {
      &:before {
        background-image: url('~/public/assets/images/icon-filter.svg');
      }
    }
    &-refresh {
      &:before {
        background-image: url('~/public/assets/images/icon-refresh.svg');
      }
    }
    &-email {
      &:before {
        background-image: url('~/public/assets/images/icon-email.svg');
      }
    }
    &-delete {
      &:before {
        background-image: url('~/public/assets/images/icon-delete.svg');
      }
    }
    &-disabled {
      &:before {
        background-image: url('~/public/assets/images/icon-disabled.svg');
      }
    }
  }

}
.fmc-button [class*=fds-font--ford-icons],
.fmc-text-button [class*=fds-font--ford-icons] {
	&:before {
		top: calc(50% - 1.2rem);
	}
}