// ================================
// Utilities: Spacing
// ================================

// --------------------------------
// Margin
// --------------------------------

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-mt-#{$size} {
        margin-top: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-mr-#{$size} {
        margin-right: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-mb-#{$size} {
        margin-bottom: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-ml-#{$size} {
        margin-left: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-my-#{$size} {
        margin-top: var(--fmc-spacing--#{$size});
        margin-bottom: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-mx-#{$size} {
        margin-left: var(--fmc-spacing--#{$size});
        margin-right: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-ma-#{$size} {
        margin: var(--fmc-spacing--#{$size});
    }
}

// --------------------------------
// Padding
// --------------------------------

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-pt-#{$size} {
        padding-top: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-pr-#{$size} {
        padding-right: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-pb-#{$size} {
        padding-bottom: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-pl-#{$size} {
        padding-left: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-py-#{$size} {
        padding-top: var(--fmc-spacing--#{$size});
        padding-bottom: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-px-#{$size} {
        padding-left: var(--fmc-spacing--#{$size});
        padding-right: var(--fmc-spacing--#{$size});
    }
}

@each $size, $pixels in $fmc-spacing-sizes {
    .fmc-pa-#{$size} {
        padding: var(--fmc-spacing--#{$size});
    }
}

// --------------------------------
// Responsive Margin
// --------------------------------

@each $breakpoint in map-keys($fds-layout-grid-columns) {
    @include fds-layout-grid-media-query_($breakpoint) {
        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-mt-#{$size}-#{$breakpoint} {
                margin-top: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-mr-#{$size}-#{$breakpoint} {
                margin-right: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-mb-#{$size}-#{$breakpoint} {
                margin-bottom: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-ml-#{$size}-#{$breakpoint} {
                margin-left: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-my-#{$size}-#{$breakpoint} {
                margin-top: var(--fmc-spacing--#{$size});
                margin-bottom: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-mx-#{$size}-#{$breakpoint} {
                margin-left: var(--fmc-spacing--#{$size});
                margin-right: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-ma-#{$size}-#{$breakpoint} {
                margin: var(--fmc-spacing--#{$size});
            }
        }
    }
}

// --------------------------------
// Responsive Padding
// --------------------------------

@each $breakpoint in map-keys($fds-layout-grid-columns) {
    @include fds-layout-grid-media-query_($breakpoint) {
        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-pt-#{$size}-#{$breakpoint} {
                padding-top: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-pr-#{$size}-#{$breakpoint} {
                padding-right: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-pb-#{$size}-#{$breakpoint} {
                padding-bottom: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-pl-#{$size}-#{$breakpoint} {
                padding-left: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-py-#{$size}-#{$breakpoint} {
                padding-top: var(--fmc-spacing--#{$size});
                padding-bottom: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-px-#{$size}-#{$breakpoint} {
                padding-left: var(--fmc-spacing--#{$size});
                padding-right: var(--fmc-spacing--#{$size});
            }
        }

        @each $size, $pixels in $fmc-spacing-sizes {
            .fmc-pa-#{$size}-#{$breakpoint} {
                padding: var(--fmc-spacing--#{$size});
            }
        }
    }
}
