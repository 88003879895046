// ================================
// Colors
// ================================

// Principal
$fds-color--primary: #00095b !default; // ford blue
$fds-color--secondary: #1700f4 !default; // grabber
$fds-color--tertiary: #00142e !default; // twilight
$fds-color--fourth: #f4f4f4 !default; // surface
$fds-color--fifth: #ecedee !default; // lds only

// Grayscale
$fds-color--black: #000 !default;
$fds-color--white: #fff !default;
$fds-color--gray1: #f2f2f2 !default; // light
$fds-color--gray2: #6e6e6e !default; // medium
$fds-color--gray3: #4d4d4d !default; // dark
$fds-color--gray4: #919191 !default; // lds only
$fds-color--gray5: #54565b !default; // lds only

// Disabled
$fds-color--disabled1: #8c8c8c !default; // graphical
$fds-color--disabled2: #9c9c9c !default; // on dark
$fds-color--disabled3: #6e6e6e !default; // on light

// Information
$fds-color--caution1: #da7029 !default; // on graphical
$fds-color--caution2: #ba4e00 !default; // on light
$fds-color--error1: #d62d0a !default; // on light
$fds-color--error2: #ff3030 !default; // on dark
$fds-color--success1: #008200 !default; // on light
$fds-color--success2: #29a429 !default; // on on dark

// Transparencies
$fds-color--primary-5: rgba($fds-color--primary, 0.05) !default;
$fds-color--primary-50: rgba($fds-color--primary, 0.5) !default;
$fds-color--primary-90: rgba($fds-color--primary, 0.9) !default;
$fds-color--tertiary-0: rgba($fds-color--tertiary, 0) !default;
$fds-color--tertiary-40: rgba($fds-color--tertiary, 0.4) !default;
$fds-color--tertiary-70: rgba($fds-color--tertiary, 0.7) !default;
$fds-color--tertiary-100: rgba($fds-color--tertiary, 1) !default;
$fds-color--black-20: rgba($fds-color--black, 0.2) !default;
$fds-color--black-40: rgba($fds-color--black, 0.4) !default;
$fds-color--black-70: rgba($fds-color--black, 0.7) !default;
$fds-color--white-0: rgba($fds-color--white, 0) !default;
$fds-color--white-10: rgba($fds-color--white, 0.1) !default;
$fds-color--white-50: rgba($fds-color--white, 0.5) !default;
$fds-color--white-80: rgba($fds-color--white, 0.8) !default;
