// ================================
// Utilities: Hide
// ================================

.fds-hide {
    display: none;
}

.fds-hide--xs {
    @include media('>=fds-xs', '<fds-sm') {
        display: none;
    }
}

.fds-hide--sm {
    @include media('>=fds-sm', '<fds-md') {
        display: none;
    }
}

.fds-hide--md {
    @include media('>=fds-md', '<fds-lg') {
        display: none;
    }
}

.fds-hide--lg {
    @include media('>=fds-lg', '<fds-xl') {
        display: none;
    }
}

.fds-hide--xl {
    @include media('>=fds-xl') {
        display: none;
    }
}

.fds-hide--mobile {
    @include media('<fds-sm') {
        display: none !important;
    }
}

.fds-hide--desktop {
    @include media('>=fds-sm') {
        display: none !important;
    }
}

.fds-hide--visually {
    @extend %fds-visually-hidden;
}

.fds-hidden {
    visibility: hidden !important;
}
