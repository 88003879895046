.fds-secondary-button {
    position: relative;

    // Disabled
    &.fds-secondary-button--disabled {
        & .fds-secondary-button__button {
            cursor: default;
            pointer-events: none;
        }
    }

    &.fds-color__bg--light {
        & .fds-secondary-button__button-text,
        & .fds-icon:first-child::before {
            color: var(--fds-color--primary);
        }

        & .fds-icon:nth-child(2)::before {
            color: var(--fds-color--white);
        }

        &.fds-secondary-button--hover,
        &.fds-secondary-button--focused,
        &.fds-secondary-button--active {
            & .fds-secondary-button__button-text {
                border-bottom: 1px solid var(--fds-color--secondary);
            }

            & .fds-secondary-button__button-text,
            & .fds-icon:first-child::before {
                color: var(--fds-color--secondary);
            }
        }

        &.fds-secondary-button--focused {
            & .fds-secondary-button__button::before {
                outline-color: var(--fds-color--primary);
            }
        }

        &.fds-secondary-button--disabled {
            & .fds-secondary-button__button-text,
            & .fds-icon:first-child::before {
                color: var(--fds-color--disabled3);
            }
        }
    }

    &.fds-color__bg--dark {
        & .fds-secondary-button__button-text,
        & .fds-icon:first-child::before {
            color: var(--fds-color--white);
        }

        & .fds-icon:nth-child(2)::before {
            color: var(--fds-color--primary);
        }

        &.fds-secondary-button--hover,
        &.fds-secondary-button--focused,
        &.fds-secondary-button--active {
            & .fds-secondary-button__button-text {
                border-bottom: 1px solid var(--fds-color--gray1);
            }

            & .fds-secondary-button__button-text,
            & .fds-icon:first-child::before {
                color: var(--fds-color--gray1);
            }
        }

        &.fds-secondary-button--focused {
            & .fds-secondary-button__button::before {
                outline-color: var(--fds-color--white);
            }
        }

        &.fds-secondary-button--disabled {
            & .fds-secondary-button__button-text,
            & .fds-icon:first-child::before {
                color: var(--fds-color--disabled2);
            }
        }
    }
}

.fds-secondary-button__button {
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    background: none;
    border: none;
    padding: fds-rem(19px) 0;
    cursor: pointer;

    // remove flash on iOS mobile as accessibility indicators are customized in this CSS
    -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        outline-offset: fds-rem(20px);
        outline: 1px solid rgba(255, 255, 255, 0);
    }

    & .fds-icon {
        position: absolute;
        top: 0;
        left: 0;
        line-height: fds-rem(18px);

        &::before {
            will-change: color;

            // Transition speeds coincide with the JavaScript, so if these speeds change,
            // the JavaScript must be updated accordingly
            transition: color 0.3s ease-in-out;
        }

        &:nth-child(2) {
            left: fds-rem(16px);
        }
    }

    // Default
    & .fds-secondary-button__button-text {
        @include fds-get-component-typeset('secondary-button');

        pointer-events: none; // Fix for IE11 :active not firing on link click
        will-change: color;
        transition: color 0.3s ease-in-out;
        border: 1px solid transparent;
        white-space: nowrap;
    }

    & .fds-secondary-button__icon-wrapper {
        position: relative;
        width: fds-rem(16px);
        height: fds-rem(18px);

        &.fds-secondary-button__icon-left {
            margin-right: fds-rem(15px);
        }

        &.fds-secondary-button__icon-right {
            margin-left: fds-rem(15px);
        }
    }
}

.fmc-text-button {
    --text-color: var(--fds-color--primary);
    --text-color-hover: var(--fds-color--secondary);
    --text-color-disabled: var(--fds-color--disabled3);
    --text-decoration: none;
    --font-weight: inherit;
    --letter-spacing: #{$fds-letter-spacing__secondary-button--sm};
    --outline: none;
    --cursor: pointer;
    --icon-offset: 1.5rem;
    --chevron-size: 1.6rem;
    --underline-weight: 0.1rem;
    --underline-offset: 0.3rem;
    --underline-color: var(--fds-color--secondary);
    --underline-color-dark: var(--fds-color--white);
    --height: #{fds-rem(40px)};

    @include fds-set-brand(lincoln) {
        --underline-weight: 0.2rem;
        --underline-offset: 0.8rem;
        --text-color-hover: var(--fds-color--primary);
        --text-color-disabled: var(--fds-color--gray4);
        --underline-color-dark: var(--fds-color--secondary);
        --letter-spacing: 0;
        --font-weight: 500;
        --chevron-size: 2.4rem;
        --icon-offset: 0;
    }

    display: inline-block;
    font-size: $fds-font-size__secondary-button--sm;
    font-stretch: condensed;
    font-weight: var(--font-weight);
    line-height: var(--height);
    letter-spacing: var(--letter-spacing);
    align-items: center;
    color: var(--text-color);
    text-decoration: var(--text-decoration);
    text-underline-offset: var(--underline-offset);
    outline: var(--outline);
    outline-offset: var(--fds-outline-offset);
    cursor: var(--cursor);
    text-decoration-thickness: var(--underline-weight); /* stylelint-disable property-no-unknown */
    text-decoration-color: var(--underline-color);
    position: relative;

    &:link {
        color: var(--text-color);
    }

    // set focus styles for all browsers
    &:focus {
        --outline: var(--fds-outline);
        --text-color: var(--text-color-hover);
        --text-decoration: underline;
        outline: var(--outline); // necessary specificity to override global :focus
    }

    // reset focus styles for browsers that support focus-visible
    &:focus:not(:focus-visible) {
        --text-color: var(--fds-color--primary);
        --text-decoration: none;
        --outline: none;

        &:hover {
            --text-decoration: underline;
            --text-color: var(--text-color-hover);
        }
    }

    // set new focus-visible styles
    &:focus-visible {
        --outline: var(--fds-outline);
        outline: var(--outline); // necessary specificity to override global :focus
    }

    @include fds-set-hover {
        --text-color: var(--text-color-hover);
        --text-decoration: underline;
    }

    @include fds-set-dark {
        --text-color: var(--fds-color--white);
        --underline-color: var(--underline-color-dark);

        &:focus {
            --text-color: var(--fds-color--white);
            --outline: var(--fds-outline-dark);
        }

        &:focus:not(:focus-visible) {
            --outline: none;

            &:hover {
                --text-color: var(--fds-color--white);
            }
        }

        &:focus-visible {
            --outline: var(--fds-outline-dark);
        }
    }

    @include fds-set-disabled {
        --text-color: var(--text-color-disabled);
        --cursor: default;
        --text-decoration: none;

        @include fds-set-dark {
            --text-color: var(--fds-color--disabled2);
        }
    }

    .fds-icon::before {
        color: var(--text-color);
    }

    .fmc-text-button__chevron {
        font-size: var(--chevron-size);
    }

    &.fmc-text-button--chevron-right::after,
    &.fmc-text-button--chevron-left::before {
        @extend %fds-icons;

        font-size: var(--chevron-size);
        display: inline-block;
        vertical-align: middle;
    }

    &.fmc-text-button--chevron-right::after {
        margin-left: var(--icon-offset);
        content: $fds-font--ford-icons__chevron-filled-right;

        @include fds-set-brand(lincoln) {
            content: $fds-font--ford-icons__chevron-right;
        }
    }

    &.fmc-text-button--chevron-left::before {
        margin-right: var(--icon-offset);
        content: $fds-font--ford-icons__chevron-filled-left;

        @include fds-set-brand(lincoln) {
            content: $fds-font--ford-icons__chevron-left;
        }
    }

    .fds-icon {
        display: inline-block;
    }
}
